import { useEffect } from "react"
import { useSelectedPrice } from "~/store/selectors"
import { useMetaPixel } from "~/utils/analytics/useMetaPixel"
import { useTiktokPixel } from "~/utils/analytics/useTiktokPixel"
import { useGoogleAnalytics } from "~/utils/analytics/useGoogleAnalytics"
import { useInternalAnalytics } from "~/utils/analytics/useInternalAnalytics"

export function useLogPurchase() {
  const selectedPrice = useSelectedPrice()
  const logMp = useMetaPixel()
  const logTtp = useTiktokPixel()
  const logGa = useGoogleAnalytics()
  const logIa = useInternalAnalytics()
  useEffect(() => {
    if (selectedPrice) {
      const productInfo = {
        priceId: selectedPrice.id,
        value: selectedPrice.trial_price_cents / 100,
        currency: selectedPrice.currency_code,
      }
      const productInfoGa = {
        items: [
          {
            item_id: selectedPrice.id,
            item_name: selectedPrice.title,
          },
        ],
        value: selectedPrice.trial_price_cents / 100,
        currency: selectedPrice.currency_code,
      }
      logMp.purchase(productInfo)
      logTtp.purchase(productInfo)
      logIa.purchase(productInfo)
      logGa.purchase(productInfoGa)
    }
  }, [logMp, logTtp, logIa, logGa, selectedPrice])
}
