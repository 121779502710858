import React, { FC, ReactNode } from "react"
import { Box, Divider, Flex, Heading, Text } from "@chakra-ui/react"
import { SkinIssues } from "~/generated/paywall"
import { VFlex, BlockPropertyContent, ProgressBar } from "~/components"
import { ActionButton } from "~/pages/Main/ActionButton"
import { useFaceScanResultIssues } from "~/store/selectors"

import { IssuesListItem } from "./IssueListItem"
import { LockIcon } from "./icons"
import { FaceScanResultRenderContainer } from "./FaceScanResultRenderContainer"

const FaceScanImage: FC = () => (
  <FaceScanResultRenderContainer
    width="calc(100% + 24px + 24px)"
    marginX={-6}
    sx={{ aspectRatio: "1" }}
  />
)

const TargetPoints: FC<{ data: SkinIssues[] }> = ({ data = [] }) => (
  <VFlex gap={3} w="full">
    <Heading as="h5" size="Header/Tertiary">
      Your target points
    </Heading>

    <Box>
      <Flex
        gap={3}
        marginX={-6}
        w="calc(100% + 24px + 24px)"
        overflow="auto"
        sx={{
          "& > div:first-of-type": {
            paddingLeft: 6,
          },
          "& > div:last-of-type": {
            paddingRight: 6,
          },
        }}
      >
        {data.map((issue, i) => (
          <IssuesListItem issue={issue} key={i} />
        ))}
      </Flex>
    </Box>
  </VFlex>
)

const TargetPointsContainer: FC = () => {
  const data = useFaceScanResultIssues()
  return data ? <TargetPoints data={data} /> : null
}

const ProgressNumber: FC<{
  color: string
  bgColor: string
  value: number
  title: string
}> = ({ color, bgColor, value, title }) => (
  <VFlex gap={0.5} justifyContent="stretch" w="full">
    <Box fontFamily="heading" textStyle="Header/Secondary">
      {value}%
    </Box>
    <ProgressBar bgColor={bgColor} color={color} value={value} />
    <Box textStyle="Paragraph/Primary">{title}</Box>
  </VFlex>
)

const FakeNumbers: FC = () => (
  <Flex gap={4}>
    <ProgressNumber color="Other/Info" bgColor="Other/Info/20" value={53} title="Hydration" />
    <ProgressNumber color="Other/Green" bgColor="Other/Green/20" value={31} title="Elasticity" />
    <ProgressNumber color="Other/New" bgColor="Other/New/20" value={87} title="Complexion" />
  </Flex>
)

const Em: FC<{ color: string; children: ReactNode }> = ({ children, color }) => (
  <Text as="span" textStyle="Subtitle/Secondary" color={color}>
    {children}
  </Text>
)

const ReportText: FC = () => (
  <VFlex gap={3}>
    <Text textStyle="Paragraph/Primary">
      Your <Em color="Other/Info">Skin Hydration</Em> report indicates that your skin retains
      moisture well, contributing to a plump and youthful appearance.
    </Text>
    <Text textStyle="Paragraph/Primary">
      Your <Em color="Other/Green">Skin Elasticity</Em> report shows that your skin has a good
      firmness and bounce, suggesting healthy collagen levels.
    </Text>
    <Text textStyle="Paragraph/Primary">
      <Em color="Other/New">Your Complexion</Em> report reflects a bright and even skin tone,
      indicating a well-balanced skincare routine.
    </Text>
  </VFlex>
)
const FADE_BG =
  "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.26) 18.17%, rgba(255, 255, 255, 0.9) 47.6%, #FFF 74.28%)"

const Fade: FC = () => (
  /* ExternalBox ? */
  <Box pos="relative" top="-222px" w="full">
    <Box pos="absolute" w="full">
      <VFlex
        gap={4}
        bg={FADE_BG}
        height="222px"
        w="full"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Box textAlign="center">
          <LockIcon fontSize="24px" color="Base/accentPrimary" />

          <Box textStyle="Subtitle/Primary" color="Base/accentPrimary">
            More data in the full report
          </Box>
        </Box>
        <ActionButton actionSource="PB_FACESCAN_RESULTS">
          <BlockPropertyContent propKey="PB_FACESCAN_RESULTS.button_text">
            Get Full Report
          </BlockPropertyContent>
        </ActionButton>
      </VFlex>
    </Box>
  </Box>
)

export const FaceScanResult: FC = () => (
  <VFlex gap={6} w="full">
    <FaceScanImage />
    <TargetPointsContainer />
    <Divider color="Base/neutralSecondary" />
    <FakeNumbers />
    <ReportText />
    <Fade />
  </VFlex>
)
