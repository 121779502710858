import React, { FC, useEffect } from "react"
import { useBlocksOrder, useCountry, useUserEmail, useUserId } from "~/store/selectors"
import { useAmplitude } from "~/utils/analytics/useAmplitude"
import { useMetaPixel } from "~/utils/analytics/useMetaPixel"
import { Blocks } from "./Blocks"
import { useInternalAnalytics } from "~/utils/analytics/useInternalAnalytics"
import { PaymentFormProvider } from "./PaymentForm/PaymentFormContext"

const MainLogEvent: FC = () => {
  const logMp = useMetaPixel()
  const logIa = useInternalAnalytics()
  const log = useAmplitude()
  const blocksOrder = useBlocksOrder()
  const email = useUserEmail("")
  const userId = useUserId()
  const country = useCountry()
  useEffect(() => {
    if (blocksOrder && userId && email) {
      log.paywallView()
      logMp.init({ email, country: country === "UNKNOWN_COUNTRY" ? undefined : country, userId })
      logMp.pageView()

      logIa.addToCart()
      logIa.pageView()
    }
  }, [log, logMp, email, userId, country, blocksOrder])

  return (
    <PaymentFormProvider>
      <Blocks data={blocksOrder} />
    </PaymentFormProvider>
  )
}

export const Main: FC = () => <MainLogEvent />
