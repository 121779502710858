import { Box, BoxProps, Divider, Flex, FlexProps, Heading, Image, Text } from "@chakra-ui/react"
import React, { FC, ReactNode } from "react"
import { Link, VFlex } from "~/components"
import { useIsLoviTheme } from "~/theme/theme-from-interview/themeProvider"
import srcStampPora from "./stamp_pora.png"
import srcStampLovi from "./stamp_lovi.png"
import srcStamp from "./stamp.webp"
import srcCards from "./cards.webp"
import { PriceContentV2_PriceItem } from "~/generated/paywall"
import { formatLengthWithUnit2, formatPrice } from "~/utils"
import { useSelectedPrice } from "~/store/selectors"

export const TextPart: FC<FlexProps> = (props) => {
  const isLoviTheme = useIsLoviTheme()
  return (
    <Flex
      bgColor={isLoviTheme ? "transparent" : "Base/neutralSecondary"}
      borderWidth={isLoviTheme ? "1px" : 0}
      borderColor="Base/neutralDisabled"
      borderRadius="2xl"
      padding={6}
      w="full"
      direction="column"
      {...props}
    />
  )
}
const TextPartContent: FC<{ slotHeader: ReactNode; children: ReactNode }> = ({
  slotHeader,
  children,
}) => {
  const isLoviTheme = useIsLoviTheme()

  return (
    <>
      <Heading size="Header/Tertiary" variant={isLoviTheme ? undefined : "text"} marginBlockEnd={3}>
        {slotHeader}
      </Heading>
      <Text textStyle="Paragraph/Secondary" color="Base/baseSecondary">
        {children}
      </Text>
    </>
  )
}

export const TextMoneyBack = () => {
  const isLoviTheme = useIsLoviTheme()

  return (
    <>
      <TextPartContent slotHeader="100% Money-Back Guarantee">
        We are confident in our service quality and its results. So, if you’re ready to reach your
        {/* fixme */}
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        goals, it's a risk-free offer. We guarantee you'll see visible results, or you'll receive a
        full refund within 30 days of your purchase. You will need to demonstrate that you have
        followed the program.
      </TextPartContent>
      <Flex direction="row" gap={2} alignItems="center" marginTop={1.5}>
        <Text textStyle="Paragraph/Secondary" color="Base/baseSecondary" flexGrow={1}>
          Find more about the applicable limitations in our{" "}
          <Link href="https://lovi.care/money-back/">money-back guarantee policy</Link>.
        </Text>
        <Image
          src={isLoviTheme ? srcStampLovi : srcStampPora}
          alt="Money back Guarantee 30 day"
          w="80px"
          h="80px"
        />
      </Flex>
    </>
  )
}

export const TextMoneyBack3 = () => (
  <VFlex gap={4} layerStyle="attentionFrame" padding={6}>
    <Image src={srcStamp} alt="Money back Guarantee 30 day" w="110px" h="110px" />
    <VFlex gap={3}>
      <Heading size="Header/Tertiary">100% Money-Back Guarantee</Heading>
      <Text textStyle="Paragraph/Secondary">
        We’re confident that Lóvi will help you achieve your skincare goals, so we’re offering a
        money-back guarantee. If you don’t see visible results in 4 weeks and can show you’ve
        followed our plan, we’ll refund your money.
      </Text>
    </VFlex>
    <Divider color="Base/baseTertiary" />
    <VFlex gap={3}>
      <Box textStyle="Subtitle/Secondary">Guaranteed Safe Checkout</Box>
      <Image src={srcCards} alt="" minH="22px" w="full" />
    </VFlex>
  </VFlex>
)

export const TextYouInformationIsSafe = () => (
  <TextPartContent slotHeader="Your information is safe">
    We will not sell or share your personal information for any marketing purposes
  </TextPartContent>
)

export const TextSecureCheckout = () => (
  <TextPartContent slotHeader="Secure checkout">
    All information is encrypted and transmitted using Secure Sockets Layer protocol
  </TextPartContent>
)

export const TextNeedHelp = () => (
  <TextPartContent slotHeader="Need help?">
    Contact us <Link href="mailto:care@pora.ai">here</Link>
  </TextPartContent>
)

export const TextCopyright = () => (
  <>
    2024 © Digital Skincare
    <br />
    <br />
    1209 Orange Street, Wilmington,
    <br />
    County of New Castle, Wilmington, Delaware 19801
  </>
)

const text3 = ({
  currency_code,
  trial_price_cents,
  subscription_price_cents,
  subscription_period_unit,
  subscription_period_length,
}: PriceContentV2_PriceItem) =>
  `Today, Lóvi will charge you ${formatPrice(
    currency_code,
    trial_price_cents / 100
  )} as an introductory offer.
Afterward, your payment method will be automatically charged the full price of ${formatPrice(
    currency_code,
    subscription_price_cents / 100
  )} every ${formatLengthWithUnit2(
    subscription_period_unit,
    subscription_period_length
  )} until you cancel the subscription.`

const SubscriptionText: FC<{ price: PriceContentV2_PriceItem }> = ({ price }) => (
  <>
    This is an autorenewing subscription.
    <br />
    <br />
    {text3(price)} You can cancel the subscription via the Lóvi app settings or by reaching out to
    support at <a href="mailto:care@lovi.care">care@lovi.care</a>.
    <br />
    <br />
    Please note that this subscription is bound by Lóvi’s{" "}
    <Link href="https://lovi.care/privacy-policy/" target="_blank">
      Privacy Policy
    </Link>
    ,{" "}
    <Link href="https://lovi.care/terms/" target="_blank">
      Terms of Use
    </Link>
    , and{" "}
    <Link href="https://lovi.care/money-back/" target="_blank">
      Refund Policy
    </Link>
    .
  </>
)

export const SubscriptionTextContainer: FC = () => {
  const selectedPrice = useSelectedPrice()
  return selectedPrice ? <SubscriptionText price={selectedPrice} /> : null
}

export const TextBox1: FC<BoxProps> = (props) => (
  <Box
    padding={4}
    borderRadius="brand16"
    borderWidth={1}
    borderColor="Base/neutralSecondary"
    textStyle="Paragraph/Tertiary"
    bgColor="Base/neutralPrimary"
    color="Base/baseDisabled"
    sx={{
      a: { color: "Base/basePrimary" },
      "a:hover": { color: "Base/accentPrimary" },
    }}
    {...props}
  />
)

export const TextBox2: FC<BoxProps> = (props) => (
  <Box
    padding={4}
    borderRadius="brand20"
    textStyle="Paragraph/Tertiary"
    bgColor="Base/neutralSecondary"
    color="Base/baseSecondary"
    sx={{
      a: { color: "Base/basePrimary" },
      "a:hover": { color: "Base/accentPrimary" },
    }}
    {...props}
  />
)
