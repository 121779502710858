import { TypedUseSelectorHook, useSelector as useReduxSelector } from "react-redux"
import { toAttributedString } from "~/components"
import {
  SkinIssues,
  type CountdownProperties,
  type Header2Properties,
  type HeaderGT2Properties,
  type HeaderGTProperties,
  type HeaderPrePaywallProperties,
  type PriceContentV2_PriceItem,
  type PriceSelectProperties,
  type WellnessProfile,
  WellnessProfile_WellnessLevel,
  PaywallBlocks,
  EnhancedPhotoProperties_EnhancementStatus,
} from "~/generated/paywall"
import type { RootState } from "../store"

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector

export const DEFAULT_DEEPLINK = "https://app.pora.ai/vqqJ/g5lgpl52"

export const useCountry = () => useSelector((state) => state.country ?? "UNKNOWN_COUNTRY")
const selectorPaymentInfo = (state: RootState) => state.payment
export const usePaymentInfo = () => useSelector(selectorPaymentInfo)
export const useDeepLink = () =>
  useSelector((state) => state.data?.links?.pora_app_deeplink ?? DEFAULT_DEEPLINK)
export const useUserEmail = (def = "UNKNOWN_EMAIL") =>
  useSelector((state) => state.data?.user?.email ?? def)
export const useUserId = () =>
  useSelector((state) => state.data?.user?.user_id ?? "UNKNOWN_USER_ID")

const upsaleSubscriptionOnExitIsActive = (state: RootState) => state.subscriptionOnExitFlow.active
const upsaleSubscriptionOnExitPrices = (state: RootState) =>
  state.data?.properties?.upsale_subscription_on_exit_properties?.prices_discounted ?? []

const pricesSelector = (state: RootState) =>
  upsaleSubscriptionOnExitIsActive(state)
    ? upsaleSubscriptionOnExitPrices(state)
    : state.data?.price?.priceses ?? []

export const usePrices = () => useSelector(pricesSelector)

const selectorUpsaleProps = (state: RootState) =>
  state.data?.properties?.upsale_subscription_properties

const selectorUpsaleSubscriptionPrice = (state: RootState) =>
  selectorUpsaleProps(state)?.upsale_price
export const useUpsaleSubscriptionPrice = () => useSelector(selectorUpsaleSubscriptionPrice)

const selectorUpsaleSubscriptionDiscountedPrice = (state: RootState) =>
  selectorUpsaleProps(state)?.upsale_price_discounted
export const useUpsaleSubscriptionDiscountedPrice = () =>
  useSelector(selectorUpsaleSubscriptionDiscountedPrice)

const selectorIsBlockExist = (state: RootState, block: PaywallBlocks) =>
  state.data?.paywall_blocks.includes(block) ?? false
export const useIsSkipTrialFlow = () =>
  useSelector((state) =>
    Boolean(
      selectorIsBlockExist(state, PaywallBlocks.PB_UPSALE_SKIP_TRIAL) &&
        selectorPaymentInfo(state).provider === "STRIPE" &&
        selectorUpsaleSubscriptionPrice(state) &&
        selectorUpsaleSubscriptionDiscountedPrice(state)
    )
  )

const selectorUpsaleWorkbookProps = (state: RootState) =>
  state.data?.properties?.upsale_workbook_properties
const selectorUpsaleWorkbookPrice = (state: RootState) => selectorUpsaleWorkbookProps(state)?.price

export const useUpsaleWorkbookPrice = () => useSelector(selectorUpsaleWorkbookPrice)

const selectorUpsaleWorkbookDiscountedPrice = (state: RootState) =>
  selectorUpsaleWorkbookProps(state)?.price_discounted
export const useUpsaleWorkbookDiscountedPrice = () =>
  useSelector(selectorUpsaleWorkbookDiscountedPrice)

const selectorUpsaleAIScannerProps = (state: RootState) =>
  state.data?.properties?.upsale_ai_scanner_properties
const selectorUpsaleAIScannerPrice = (state: RootState) =>
  selectorUpsaleAIScannerProps(state)?.price

export const useUpsaleAIScannerPrice = () => useSelector(selectorUpsaleAIScannerPrice)

const selectorUpsaleAIScannerDiscountedPrice = (state: RootState) =>
  selectorUpsaleAIScannerProps(state)?.price_discounted
export const useUpsaleAIScannerDiscountedPrice = () =>
  useSelector(selectorUpsaleAIScannerDiscountedPrice)

export const selectorUpsaleNewSubscriptionsData = (state: RootState) =>
  state.data?.properties?.upsale_new_subscriptions_properties
export const useUpsaleNewSubscriptionsData = () => useSelector(selectorUpsaleNewSubscriptionsData)

export const useIsWorkbookFlow = () =>
  useSelector((state) =>
    Boolean(
      selectorIsBlockExist(state, PaywallBlocks.PB_UPSALE_WORKBOOK) &&
        selectorPaymentInfo(state).provider === "PALTA" &&
        selectorUpsaleWorkbookPrice(state) &&
        selectorUpsaleWorkbookDiscountedPrice(state)
    )
  )

export const useIsAiScanFlow = () =>
  useSelector((state) =>
    Boolean(
      selectorIsBlockExist(state, PaywallBlocks.PB_UPSALE_AI_SCANNER) &&
        selectorPaymentInfo(state).provider === "PALTA" &&
        selectorUpsaleAIScannerPrice(state) &&
        selectorUpsaleAIScannerDiscountedPrice(state)
    )
  )

export const useIsAiScanFlowV2 = () =>
  useSelector((state) =>
    Boolean(
      selectorIsBlockExist(state, PaywallBlocks.PB_UPSALE_AI_SCANNER_2) &&
        selectorPaymentInfo(state).provider === "PALTA" &&
        selectorUpsaleAIScannerPrice(state) &&
        selectorUpsaleAIScannerDiscountedPrice(state)
    )
  )

export const useIsBundleUpsaleFlow = () =>
  useSelector((state) =>
    Boolean(
      selectorIsBlockExist(state, PaywallBlocks.PB_UPSALE_NEW_SUBSCRIPTIONS) &&
        selectorPaymentInfo(state).provider === "PALTA" &&
        selectorUpsaleNewSubscriptionsData(state)?.prices.length
    )
  )

export const useIsPaymentFormInPopup = () =>
  useSelector((state) =>
    Boolean(selectorIsBlockExist(state, PaywallBlocks.PB_PAYMENT_FORM_WITH_INVOICE_3_IN_POPUP))
  )

export const useSummaryList = () => useSelector((state) => state.data?.summary?.list ?? [])
export const useSpecialOfferOpts = () =>
  useSelector(
    (state) =>
      state.data?.properties?.offer_popup_properties ?? {
        text: undefined,
        button_text: undefined,
        display_timeout: 3,
        await_scroll: true,
      }
  )
export const usePriceSectionTitle = () => useSelector((state) => state.data?.price?.title)
export const usePayButtonTitle = () => useSelector((state) => state.data?.price?.button_text)
export const useIsContentLoaded = () => useSelector((state) => Boolean(state.data))
//export const useIsContentLoading = () => useSelector((state) => Boolean(state.isLoadingPageOpen))
export const useAnalyticsParameters = () =>
  useSelector((state) => state.data?.analytics_event_parameters ?? {})
export const useBlocksOrder = () => useSelector((state) => state.data?.paywall_blocks ?? [])
export const useProgram2Data = () =>
  useSelector(
    (state) =>
      state.data?.properties?.program2_properties ?? {
        title: "",
        description: "",
        issues: [],
      }
  )

export const useHeader2Data = () =>
  useSelector(
    (state): Header2Properties =>
      state.data?.properties?.header2_properties ?? {
        title: toAttributedString(""),
        countdown: {
          title: "",
          minutes: 0,
        },
      }
  )

export const usePriceSelectData = () =>
  useSelector(
    (state): PriceSelectProperties =>
      state.data?.properties?.price_select_properties ?? {
        button_text: "",
        pre_selector_texts: [],
      }
  )

export const useSelectedPrice = () =>
  useSelector((state): PriceContentV2_PriceItem | undefined =>
    pricesSelector(state).find(({ id }) => id === state.selectedPriceId)
  )

export const usePaymentProvidersPreference = () =>
  useSelector((state) => state.data?.properties?.payment_form_properties?.provider_preference ?? [])

export const useHeaderPrePaywallData = () =>
  useSelector(
    (state): HeaderPrePaywallProperties =>
      state.data?.properties?.header_pre_paywall_properties ?? {
        main_goal_text: "Your skin will be improved in 4 weeks",
        main_goal_img: "",
      }
  )

export const useHeaderGTData = () =>
  useSelector(
    (state): HeaderGTProperties =>
      //todo fixme
      state.data?.properties?.header_gt_properties ?? {
        main_goal_text: "Your skin will be improved in 4 weeks",
        main_goal_img: "",
      }
  )

export const useHeaderGT2Data = () =>
  useSelector(
    (state): HeaderGT2Properties =>
      //todo fixme
      state.data?.properties?.header_gt2_properties ?? {
        main_goal_text: "Your skin will be improved in 4 weeks",
        main_goal_img: "",
      }
  )

export const useHeaderLuvlyData = () =>
  useSelector(
    (state): HeaderGT2Properties =>
      //todo fixme
      state.data?.properties?.header_luvly_properties ?? {
        main_goal_text: "Your skin will be improved in 4 weeks",
        main_goal_img: "",
      }
  )

export const useCountdownData = () =>
  useSelector(
    (state): CountdownProperties =>
      state.data?.properties?.countdown ?? {
        title: "",
        minutes: 0,
      }
  )

export const useWellnessProfileData = () =>
  useSelector(
    (state): WellnessProfile =>
      state.data?.properties?.wellness_profile ?? {
        goal: SkinIssues.SKIN_ISSUE_UNSPECIFIED,
        level: WellnessProfile_WellnessLevel.GOOD,
      }
  )

export const useFaceScanResultIssues = () =>
  useSelector(
    (state): SkinIssues[] => state.data?.properties?.face_scan_result_properties?.issues ?? []
  )
export const useFaceScanResultScan = () =>
  useSelector((state) => state.data?.properties?.face_scan_result_properties?.scan_url)

export const usePaymentFormPopupIsOpen = () =>
  useSelector((state): boolean => state.paymentFormPopup.isOpen)

const selectorPaywallVariantProperties = (state: RootState) =>
  state.data?.variants_properties?.properties

export const useBlockProperties = () =>
  useSelector((state): Record<string, string> => selectorPaywallVariantProperties(state) ?? {})

export const useUpsaleSubscriptionOnExitState = () =>
  useSelector((state) => state.subscriptionOnExitFlow)

export const useUpsaleSubscriptionOnExitData = () =>
  useSelector(
    (state) =>
      state.data?.properties?.upsale_subscription_on_exit_properties ?? {
        prices_discounted: [],
        discount_percent: 0,
      }
  )

export const useEnhancedPhotoData = () =>
  useSelector(
    (state) =>
      state.data?.properties?.enhanced_photo_properties ?? {
        status: EnhancedPhotoProperties_EnhancementStatus.ENHANCEMENT_STATUS_UNKNOWN,
        original_photo_url: "https://storage.pora.ai/6e4d34d235ebe6cec99b7965b3cef9f5.webp",
        enhanced_photo_url: "https://storage.pora.ai/d1b44adf0113ad30269d6648ab74e4c6.webp",
      }
  )

export const useAISkinCarePlanProperties = () =>
  useSelector(
    (state) =>
      state.data?.properties?.ai_skincare_plan_properties ?? {
        blocks: [],
      }
  )
