import React, { useMemo } from "react"
import { AttributedStringType, Timer } from "~/components"
import { HeaderGT } from "~/pages/Main/HeaderGT/HeaderGT"
import { useCountdownData, useHeaderGTData } from "~/store/selectors"
import { useScrollToOffer } from "../scrollToOffer"
import { PROGRAM_LENGTH_WEEKS } from "~/constants"
import { getProgramEndDate } from "~/utils"

const defaultTitle = {
  parts: [
    {
      content: "Your ",
      format: [],
    },
    {
      content: "personalized\n",
      format: [
        {
          format: {
            emphasis: true,
            $case: "emphasis",
          },
        },
      ],
    },
    {
      content: `${PROGRAM_LENGTH_WEEKS}-week program`,
      format: [],
    },
  ],
} as AttributedStringType

export const HeaderGTContainer = ({ title = defaultTitle }: { title?: AttributedStringType }) => {
  const { main_goal_text: resultsText, main_goal_img: graph } = useHeaderGTData()
  const handleTimerBlockClick = useScrollToOffer("header_timer")
  const startDate = useMemo(() => new Date(), [])
  const endDate = useMemo(() => getProgramEndDate(PROGRAM_LENGTH_WEEKS), [])
  const { minutes } = useCountdownData()
  const timerDescription = `Your personalized skin care program has been reserved for the next ${minutes} minutes`

  return (
    <HeaderGT
      onTimerBlockClick={handleTimerBlockClick}
      title={title}
      timerDescription={timerDescription}
      timeBlock={<Timer />}
      graphImg={graph}
      startDate={startDate}
      endDate={endDate}
      resultsText={resultsText}
      programLengthWeeks={PROGRAM_LENGTH_WEEKS}
    />
  )
}
