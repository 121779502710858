import React, { useEffect } from "react"

import { SkipTrialContainer } from "~/pages/Main/SkipTrial/SkipTrialContainer"
import {
  useIsSkipTrialFlow,
  useIsWorkbookFlow,
  useIsAiScanFlow,
  useIsAiScanFlowV2,
  useIsBundleUpsaleFlow,
} from "~/store/selectors"
import { useLogPurchase } from "~/pages/Processing/useLogPurchase"

import { UpsaleWorkbookContainer } from "./UpsaleWorkbook"
import { UpSaleAI } from "./UpsaleAI"
import { NavigateSuccessLink } from "./NavigateSuccessLink"
import { UpsaleAI2 } from "~/pages/Processing/UpsaleAI2"
import { UpsaleNewSubscriptionsContainer } from "~/pages/Processing/UpsaleNewSubscriptions"

export function ProcessingSuccessContainer() {
  useLogPurchase()
  const isSkipTrialFlow = useIsSkipTrialFlow()
  const isWorkbookFlow = useIsWorkbookFlow()
  const isAiScanFlow = useIsAiScanFlow()
  const isAiScanFlowV2 = useIsAiScanFlowV2()
  const isBundleUpsaleFlow = useIsBundleUpsaleFlow()

  useEffect(() => {
    const root = document.getElementById("root")

    if (root) {
      root.scrollTo(0, 0)
    }
  }, [])

  if (isBundleUpsaleFlow) {
    return <UpsaleNewSubscriptionsContainer />
  }

  if (isSkipTrialFlow) {
    return <SkipTrialContainer />
  }

  if (isAiScanFlow) {
    return <UpSaleAI />
  }

  if (isAiScanFlowV2) {
    return <UpsaleAI2 />
  }

  if (isWorkbookFlow) {
    return <UpsaleWorkbookContainer />
  }

  return <NavigateSuccessLink />
}
