import React, { FC, ReactNode, useCallback, useState } from "react"
import {
  Box,
  Button,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react"
import { VFlex } from "~/components"

import chartSrc from "./chart.svg"
import { useUpsaleSubscriptionOnExit } from "./useUpsaleSubscriptionOnExit"

const Content: FC<{ onClick: () => void; discountPercent: number }> = ({
  onClick,
  discountPercent,
}) => (
  <VFlex gap={4} alignItems="center">
    <VFlex gap={2} alignItems="center">
      <Heading size="Header/Secondary">Did you know?</Heading>
      <Box
        textStyle="Paragraph/Primary"
        textAlign="center"
        sx={{
          "& em": {
            color: "Base/accentPrimary",
            fontWeight: 600,
            fontStyle: "normal",
          },
        }}
      >
        <em>71%</em> of Lóvi subscribers advance in their
        <br /> goals within the <em>first month</em>
      </Box>
    </VFlex>
    <Box alignItems="flex-end" w="full">
      <Image src={chartSrc} minHeight="196px" w="full" pl={4} />
    </Box>
    <VFlex
      gap={2}
      padding={2}
      bgColor="Base/accentPrimaryBg"
      alignItems="center"
      borderRadius="brand24"
      w="full"
    >
      <Box textStyle="Paragraph/Primary" textAlign="center">
        We want you to see the best
        <br /> results possible! Don&apos;t miss your
      </Box>
      <Box textStyle="Header/Primary" fontFamily="heading" color="Base/accentPrimary">
        –{discountPercent}% off
      </Box>
    </VFlex>
    <Button variant="next" w="full" onClick={onClick}>
      Wow
    </Button>
  </VFlex>
)

export const UpsaleSubscriptionOnExitModal: FC<{
  isOpen: boolean
  onClose: () => void
  children: ReactNode
}> = ({ isOpen, onClose, children }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent borderRadius="brand32" bg="white" marginX={4}>
      <ModalCloseButton
        borderRadius="full"
        bgColor="Base/neutralSecondary"
        color="Base/baseSecondary"
        top={4}
        right={4}
      />
      <ModalBody paddingX={6} paddingBottom={6} paddingTop={16}>
        {children}
      </ModalBody>
    </ModalContent>
  </Modal>
)

export const UpsaleSubscriptionOnExitModalContainer: FC = () => {
  const { popupIsOpen, close: onClose, data } = useUpsaleSubscriptionOnExit()
  return (
    <>
      <link href={chartSrc} rel="preload" as="image" />
      <UpsaleSubscriptionOnExitModal isOpen={popupIsOpen} onClose={onClose}>
        <Content onClick={onClose} discountPercent={data.discount_percent} />
      </UpsaleSubscriptionOnExitModal>
    </>
  )
}
