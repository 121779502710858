import { Box, Flex, FlexProps, Image, SimpleGrid, Tag, TagProps } from "@chakra-ui/react"
import React, { FC } from "react"
import { easeInOut, useTransform } from "framer-motion"
import { ProgressBar, VFlex } from "~/components"
import { useProgress, useStateFromMV } from "~/shared/useProgress"

import sparksIconSrc from "./sparksIcon.min.svg?url"
import { formatPercent } from "~/utils"

const Subtitle: FC<FlexProps> = (props) => (
  <Flex
    textStyle="Paragraph/Secondary"
    alignItems="center"
    gap={0.5}
    sx={{
      " em": { fontWeight: 500, fontStyle: "normal" },
    }}
    {...props}
  />
)

const PhotoTag: FC<TagProps> = (props) => (
  <Tag
    borderRadius="full"
    paddingX={3}
    paddingY={1.5}
    lineHeight={5}
    textStyle="Subtitle/Tertiary"
    {...props}
  />
)

const PhotoItem: FC<FlexProps> = (props) => (
  <Flex
    flexGrow={1}
    borderRightRadius="brand24"
    borderLeftRadius="brand24"
    bgSize="cover"
    minH="200px"
    alignItems="flex-end"
    justifyContent="center"
    padding={2}
    sx={{ aspectRatio: "0.75" }}
    {...props}
  />
)

type ProgressNumberProps = {
  color: string
  bgColor?: string
  value: number
  title: string
}
const ProgressNumber: FC<ProgressNumberProps> = ({
  color,
  bgColor = "Base/neutralDisabled",
  value,
  title,
}) => (
  <VFlex gap={0.5} justifyContent="stretch" w="full" paddingX={6}>
    <Box textStyle="Header/Tertiary" fontFamily="heading">
      {formatPercent(value)}
    </Box>
    <ProgressBar bgColor={bgColor} h={2} color={color} value={value} />
    <Box textStyle="Paragraph/Secondary">{title}</Box>
  </VFlex>
)

const ProgressNumberAnimated: FC<ProgressNumberProps> = ({ value, ...props }) => {
  const { motionValue } = useProgress({ duration: 0.5 })
  const transformed = useTransform(motionValue, (v) => v * easeInOut(v) * value)
  const progress = useStateFromMV(transformed)

  return <ProgressNumber value={progress} {...props} />
}

const defaultOriginalPhoto = "https://storage.pora.ai/6e4d34d235ebe6cec99b7965b3cef9f5.webp"
const defaultEnhancedPhoto = "https://storage.pora.ai/d1b44adf0113ad30269d6648ab74e4c6.webp"

const texts = {
  default: {
    supTitle: (
      <>
        2458 people <em>improved their results with Lóvi</em>
      </>
    ),
    tagBefore: "Before",
    tagAfter: "After",
  },
  user: {
    supTitle: (
      <>
        {/* eslint-disable-next-line no-irregular-whitespace */}
        AI Prediction <em>based on your goals</em>
      </>
    ),
    tagBefore: "Now",
    tagAfter: "Your Goal",
  },
}

export const EnhancedPhoto: FC<{
  subTitle?: string
  type: "user" | "default"
  originalPhoto: string | undefined
  enhancedPhoto: string | undefined
}> = ({ subTitle, type, originalPhoto, enhancedPhoto }) => (
  <VFlex mt={4} gap={4} w="full">
    <Subtitle placeContent="center">
      <Image src={sparksIconSrc} w="20px" h="20px" display="inline-block" />
      {texts[type].supTitle}
    </Subtitle>
    <SimpleGrid columns={2} spacingX={0.5} spacingY={4} w="full">
      <PhotoItem
        borderRightRadius="brand4"
        bgImage={type === "user" ? originalPhoto : defaultOriginalPhoto}
        justifyContent="flex-start"
      >
        <PhotoTag color="Base/baseSecondary">{texts[type].tagBefore}</PhotoTag>
      </PhotoItem>
      <PhotoItem
        borderLeftRadius="brand4"
        bgImage={type === "user" ? enhancedPhoto : defaultEnhancedPhoto}
        justifyContent="flex-end"
      >
        <PhotoTag color="Base/accentPrimary">{texts[type].tagAfter}</PhotoTag>
      </PhotoItem>

      <ProgressNumberAnimated value={53} color="Other/Info/80" title="Skin Hydration" />
      <ProgressNumberAnimated value={92} color="Other/Info" title="Skin Hydration" />
      <ProgressNumberAnimated value={31} color="Other/Green/80" title="Skin Elasticity" />
      <ProgressNumberAnimated value={89} color="Other/Green" title="Skin Elasticity" />
    </SimpleGrid>
    {subTitle && (
      <Subtitle placeContent="flex-start" color="Base/baseSecondary" paddingX={6}>
        {subTitle}
      </Subtitle>
    )}
  </VFlex>
)
