import React, { FC, useCallback, useMemo } from "react"
import { Timer } from "~/components"
import { getProgramEndDate } from "~/utils"
import { PROGRAM_LENGTH_WEEKS } from "~/constants"
import { useBlockProperties, useCountdownData } from "~/store/selectors"

import { useScrollToOffer } from "../../scrollToOffer"
import { useAmplitude } from "~/utils/analytics/useAmplitude"

import { BlockWrapper, BlockWrapperProps } from "../BlockWrapper"
import { HeaderLuvly } from "./HeaderLuvly"
//import chartSrc from "./chart.svg?url"
const chartSrc = "https://storage.pora.ai/a961efbb86913e5b111d1fa8942ea27e.webp"
const HeaderWithListTimerChartContainer = () => {
  const resultsText = "Dream Skin"

  const log = useAmplitude()

  const timerBlockClick = useScrollToOffer("header_timer")
  const onTimerBlockClick = useCallback(() => {
    log.click({ source: "header_timer" })
    timerBlockClick()
  }, [log, timerBlockClick])

  const endDate = useMemo(() => getProgramEndDate(PROGRAM_LENGTH_WEEKS), [])
  const { minutes } = useCountdownData()
  const timerDescription = `Your personalized skin care plan has been reserved for the next ${minutes} minutes`
  const blockProperties = useBlockProperties()
  const title =
    blockProperties["PB_HEADER_WITH_LIST_TIMER_CHART.title"] ?? `Your personalized\nplan is ready!`
  const chart = blockProperties["PB_HEADER_WITH_LIST_TIMER_CHART.chart"] ?? chartSrc

  return (
    <HeaderLuvly
      onTimerBlockClick={onTimerBlockClick}
      title={title}
      timerDescription={timerDescription}
      timeBlock={<Timer />}
      graphImg={chart}
      endDate={endDate}
      resultsText={resultsText}
      programLengthWeeks={PROGRAM_LENGTH_WEEKS}
      variant={2}
    />
  )
}

export const HeaderWithListTimerChartBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper {...props} paddingTop={4} overflow="hidden">
    <HeaderWithListTimerChartContainer />
  </BlockWrapper>
)
