import React, { FC, useCallback, useEffect, useState } from "react"
import { Box, Button, Center, useBoolean } from "@chakra-ui/react"

import { VFlex } from "~/components/VFlex"
import { ButtonSlideUpContainer } from "~/components/ButtonSlideUpContainer"
import { useUpsaleWorkbookDiscountedPrice, useUpsaleWorkbookPrice } from "~/store/selectors"

import { Step2Modal } from "./Step2Modal"
import { WorkbookHeader, WorkbookCard, DiscountCard, PriceInfo } from "./WorkbookCard"
import { makePaltaPurchase } from "~/api/api"
import { useAuthInfo } from "~/utils/useAuthInfo"
import { useNavigateSuccess } from "../NavigateSuccessLink"
import { useAmplitude } from "~/utils/analytics/useAmplitude"
import { Logo } from "~/pages/Main/SkipTrial/Icons"
import { PriceContentV2_PriceItem } from "~/generated/paywall"

type Step = 0 | 1 | 2
export const UpSaleWorkbook: FC<{
  step: Step
  isLoading: boolean
  price: PriceContentV2_PriceItem
  discountPrice: PriceContentV2_PriceItem
  onClickPay: () => void
  onClickNext: () => void
}> = ({ step, onClickPay, onClickNext, isLoading = false, price, discountPrice }) => {
  useEffect(() => {
    if (step == 2) {
      document.querySelector("#discount-card")?.scrollIntoView()
    }
  }, [step])
  return (
    <>
      <VFlex gap={6} bgColor="white" paddingBottom="160px" paddingX={6}>
        <Box>
          <Center mb={4} height="56px" as="header">
            <Logo />
          </Center>
          <WorkbookHeader />
        </Box>
        <VFlex gap={2}>
          <WorkbookCard>
            <PriceInfo isDiscounted={step === 2} price={price} priceDiscounted={discountPrice} />
          </WorkbookCard>
          {step === 2 && <DiscountCard discount={discountPrice.subscription_text} />}
        </VFlex>
        <Step2Modal isOpen={step === 1} onClose={onClickNext} />
      </VFlex>
      <ButtonSlideUpContainer visible>
        <VFlex gap={2}>
          <Button isLoading={isLoading} w="full" variant="action" onClick={onClickPay}>
            Buy Workbook
          </Button>
          <Button disabled={isLoading} onClick={onClickNext} w="full" variant="secondary">
            Continue without Workbook
          </Button>
        </VFlex>
      </ButtonSlideUpContainer>
    </>
  )
}

export const UpsaleWorkbookContainer: FC = () => {
  const navigateSuccess = useNavigateSuccess()
  const { user } = useAuthInfo()
  const { userId } = user ?? {}
  const price = useUpsaleWorkbookPrice()
  const discountPrice = useUpsaleWorkbookDiscountedPrice()
  const [step, setStep] = useState<Step>(0)
  const [isLoading, setLoading] = useBoolean()
  const log = useAmplitude()
  useEffect(() => {
    log.upsaleWorkbookView({ step })
  }, [log, step])

  useEffect(() => {
    document.querySelector("html")?.scrollTo(0, 0)
  }, [])

  const onFail = useCallback(() => {
    log.upsaleWorkbookBuyFail()
    navigateSuccess("workbookFail")
  }, [log, navigateSuccess])

  const onSuccess = useCallback(() => {
    log.upsaleWorkbookBuySuccess()
    navigateSuccess("workbookSuccess")
  }, [log, navigateSuccess])

  const onNext = useCallback(() => {
    if (step === 0) {
      setStep(1)
      return
    }
    if (step === 1) {
      setStep(2)
      return
    }
    if (step === 2) {
      log.upsaleWorkbookExit()
      onFail()
      return
    }
  }, [step, setStep])

  const onPay = useCallback(async (): Promise<boolean> => {
    const selectedPrice = step === 0 ? price : discountPrice
    /* TODO: remove hardcoded payment_providers */
    const priceId = selectedPrice?.payment_providers?.palta_card?.price_id
    try {
      if (!userId) {
        throw new Error("Unknown UserId")
      }
      if (!priceId) {
        throw new Error("Unknown PriceId")
      }
      setLoading.on()
      log.upsaleWorkbookBuy({ priceId })
      const result = await makePaltaPurchase({ priceId, userId })
      if (result) {
        setLoading.off()
        onSuccess()
        return true
      }
    } catch (error) {
      log.upsaleWorkbookBuyError()
      onFail()
    }
    return false
  }, [step])

  if (!price || !discountPrice) {
    return null
  }

  return (
    <UpSaleWorkbook
      step={step}
      isLoading={isLoading}
      onClickNext={onNext}
      onClickPay={onPay}
      price={price}
      discountPrice={discountPrice}
    />
  )
}
