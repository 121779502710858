import React, { FC } from "react"
import { Heading } from "@chakra-ui/react"
import { noop } from "~/utils"
import { useSelectedPrice } from "~/store/selectors"
import type { PriceContentV2_PriceItem } from "~/generated/paywall"
import { Invoice3 } from "~/pages/Main/Invoice"
import { PaymentFormPaltaPopupContainer } from "~/pages/Main/PaymentForm"
import { SubscriptionTextContainer, TextBox1 } from "~/components/StaticTexts"

import { ModalHiddenWrapper } from "./ModalHiddenWrapper"
import { usePaymentFormPopupDisclosure } from "./usePaymentFormPopupDisclosure"

export const PaymentFormWithInvoice3InPopup: FC<{
  hidden?: boolean
  onClose?: () => void
  selectedPrice: PriceContentV2_PriceItem
}> = ({ selectedPrice, hidden = false, onClose = noop }) => {
  return (
    <ModalHiddenWrapper hidden={hidden} onClose={onClose}>
      <Heading size="Header/Secondary" marginBottom={6} marginTop="-10px">
        Select a payment method
      </Heading>
      <PaymentFormPaltaPopupContainer>
        <Invoice3 price={selectedPrice} />
        <TextBox1>
          <SubscriptionTextContainer />
        </TextBox1>
      </PaymentFormPaltaPopupContainer>
    </ModalHiddenWrapper>
  )
}

export const PaymentFormWithInvoice3InPopupBlock: FC = () => {
  const selectedPrice = useSelectedPrice()
  const { isOpen, close } = usePaymentFormPopupDisclosure()
  if (selectedPrice) {
    return (
      <PaymentFormWithInvoice3InPopup
        hidden={!isOpen}
        onClose={close}
        selectedPrice={selectedPrice}
      />
    )
  }
  return null
}
