import React, { FC, ReactNode } from "react"
import { Box, Divider, Flex, FlexProps, Text } from "@chakra-ui/react"
import { formatLengthWithUnit2, formatPrice, PeriodUnit } from "~/utils"
import { VFlex } from "~/components"
import { PriceContentV2_PriceItem } from "~/generated/paywall"

const defaultUnit = PeriodUnit.PERIOD_UNIT_UNSPECIFIED

const Row: FC<FlexProps> = (props) => (
  <Flex justify="space-between" align="center" alignSelf="stretch" direction="row" {...props} />
)

const RowContent: FC<{ slot1: ReactNode; slot2: ReactNode }> = ({ slot1, slot2 }) => (
  <Row>
    <Box textStyle="Paragraph/Primary">{slot1}</Box>
    <Box textStyle="Subtitle/Secondary">{slot2}</Box>
  </Row>
)

export const Invoice3: FC<{ price: PriceContentV2_PriceItem }> = ({
  price: {
    currency_code,
    title = "",
    trial_price_cents = Number.NaN,
    subscription_period_length = 1,
    subscription_period_unit = defaultUnit,
    subscription_price_cents = Number.NaN,
    economy_price = "0",
    economy_percent = 0,
  },
}) => (
  <>
    <VFlex gap={5} w="full">
      <VFlex gap={2}>
        <RowContent
          slot1={title}
          slot2={formatPrice(currency_code, subscription_price_cents / 100)}
        />
        <RowContent
          slot1={`-${economy_percent}% discount`}
          slot2={formatPrice(currency_code, Number.parseFloat(economy_price))}
        />
      </VFlex>

      <Divider color="Base/neutralSecondary" />

      <Row>
        <Box textStyle="Subtitle/Primary">Total</Box>
        <Box textStyle="Subtitle/Primary" color="Base/baseDisabled">
          <Text as="span" color="Other/Info">
            {formatPrice(currency_code, trial_price_cents / 100)}
          </Text>
          {" / "}
          {formatLengthWithUnit2(subscription_period_unit, subscription_period_length)}
        </Box>
      </Row>
    </VFlex>
  </>
)
