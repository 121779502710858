import { createPaymentClient, PaymentClient, PaymentClientSettings } from "@palta-brain/payments"
import {
  ApplePayOptions,
  PaymentFlow,
  PayPalOptions,
  UniversalCheckoutOptions,
} from "@primer-io/checkout-web"
import "@primer-io/checkout-web/dist/Checkout.css"
import * as Sentry from "@sentry/react"
import { v4 as uuid } from "uuid" // TODO replace by crypto.randomUUID()
import { CONFIG } from "~/config"
import { noop } from "~/utils"
import { serialQueue } from "./serialQueue"

const API_ENDPOINT = CONFIG.payments.api
const API_KEY = CONFIG.payments.key
const PAYMENTS_WORKFLOW = CONFIG.payments.workflow
const CARD_META = { card: 1, apple: 1, apple_pay: 1, paypal: 1 }
const PAYPAL_META = { paypal: 1 }

export type PaymentFormEvent =
  | "form.init"
  | "form.field.focusin"
  | "form.field.focusout"
  | "form.click"
  | "form.scene.change"
  | "form.init"
  | "form.init.fail"
  | "form.init.success"
  | "form.init.complete"
  | "payment.change"
  | "payment.error"

const PAYMENT_FORM_STYLES_PORA = {
  loadingScreen: {
    color: "var(--chakra-colors-Base\\/accentPrimary)", // FIXME
  },
  submitButton: {
    base: {
      color: "var(--chakra-colors-Base\\/neutralPrimary)",
      background: "var(--chakra-colors-Base\\/accentPrimary)",
      borderRadius: "20px",
      fontFamily: "Inter",
      fontWeight: "medium",
      fontSize: "18px",
      lineHeight: "22px",
      boxShadow: "none",
    },
  },
  formSpacings: {},
}

const PAYMENT_FORM_STYLES_LOVI = {
  ...PAYMENT_FORM_STYLES_PORA,
  submitButton: {
    base: {
      ...PAYMENT_FORM_STYLES_PORA.submitButton.base,
      height: "60px",
      fontWeight: "500",
      borderRadius: "var(--chakra-radii-full)",
      background: "var(--chakra-colors-Base\\/accentPrimary)",
      boxShadow: "var(--chakra-shadows-LoviActionButton)",
      hover: {
        transform: "scale(.95)",
      },
      focus: {
        transform: "scale(.95)",
      },
    },
  },
}

const PAYMENT_FORM_STYLES_LOVI_POPUP = {
  ...PAYMENT_FORM_STYLES_LOVI,
  paymentMethodButton: {
    height: "60px",
  },
  submitButton: {
    base: {
      fontFamily: "Inter",

      height: "60px",
      fontWeight: "500",
      fontSize: "18px",
      lineHeight: "24px",
      letterSpacing: "-0.5px",
      color: "var(--chakra-colors-Base\\/neutralPrimary)",
      background: "var(--chakra-colors-Base\\/basePrimary)",
      borderRadius: "var(--chakra-radii-full)",
      boxShadow: "none",
    },
  },
}

const PAYPAL_DEFAULT_STYLES = {
  buttonColor: "black",
  buttonShape: "rect",
  buttonSize: "responsive",
  buttonHeight: 55, // 55px is the maximum for height
  buttonLabel: "paypal",
  paymentFlow: PaymentFlow.PREFER_VAULT,
} as const

const PAYPAL_POPUP_STYLES = {
  ...PAYPAL_DEFAULT_STYLES,
  buttonColor: "gold",
  buttonShape: "pill",
} as const

let billingAddress: Record<string, string> | undefined = undefined
if (PAYMENTS_WORKFLOW.toLowerCase() === "skincare-sandbox") {
  billingAddress = {
    addressLine1: "Main St",
    addressLine2: "175",
    city: "Montpelier",
    countryCode: "US",
    postalCode: "05602",
  }
}
type FormType = "card" | "paypal"
type ClientSettingsArgs = Pick<PaymentClientSettings, "onPaymentStatusChange" | "onError"> & {
  formType: FormType
}
const clientSettings = ({
  formType,
  onPaymentStatusChange = noop,
  onError = noop,
}: ClientSettingsArgs): PaymentClientSettings => ({
  apiEndpoint: API_ENDPOINT,
  apiKey: API_KEY,
  metadata: {
    workflow: PAYMENTS_WORKFLOW,
    ...(formType === "card" ? CARD_META : PAYPAL_META),
  },
  onError,
  onPaymentStatusChange,
  reCaptchaEnabled: false,
  reCaptchaSiteKey: undefined,
})

type FormArguments = {
  priceId: string
  userId: string
  countryCode: string
  emailAddress: string
}

type FormVariant = "DEFAULT" | "PALTA_POPUP"

const paymentFormOptions = ({
  container,
  applepayContainer,
  paypalContainer,
  onEntering,
  theme,
  variant = "DEFAULT",
}: {
  container: HTMLElement
  applepayContainer?: HTMLElement
  paypalContainer?: HTMLElement
  onEntering: (sceneId: string) => void
  theme: Theme
  variant?: FormVariant
}): UniversalCheckoutOptions => {
  const applePay: Partial<ApplePayOptions> = {
    buttonType: "INVALID" as "plain", // Will be overwritten by '-apple-pay-button-type' CSS property
    buttonStyle: "black",
    captureBillingAddress: false,
  }
  if (applepayContainer) {
    applePay.container = applepayContainer as unknown as string
  }

  const paypal: Partial<PayPalOptions> =
    variant === "PALTA_POPUP" ? PAYPAL_POPUP_STYLES : PAYPAL_DEFAULT_STYLES
  if (paypalContainer) {
    paypal.container = paypalContainer as unknown as string
  }

  let style = PAYMENT_FORM_STYLES_PORA
  if (theme === "lovi") {
    style = PAYMENT_FORM_STYLES_LOVI
    if (variant === "PALTA_POPUP") {
      style = PAYMENT_FORM_STYLES_LOVI_POPUP
    }
  }

  const result: UniversalCheckoutOptions = {
    container,
    locale: "en-US",
    vault: { visible: false },
    scene: { onEntering },
    card: { cardholderName: { visible: false } },
    paypal,
    applePay,
    style,
    form: { inputLabelsVisible: true },
    successScreen: false,
  }

  result.form!.inputLabelsVisible = false
  result.style!.formSpacings = {
    betweenLabelAndInput: "0px",
    betweenInputs: "8px",
  }

  return result
}

const clearContainer = (src: HTMLElement | null) => {
  if (src) {
    src.childNodes.forEach((node) => [src.removeChild(node)])
  }
}

let isSubscribed = false
const globalEvents = (fire: (event: PaymentFormEvent, params: any) => void): void => {
  /* see https://paltaskincare.atlassian.net/browse/GEN-2057 */

  window.addEventListener("message", (e) => {
    let data = e.data ?? {}
    if (typeof e.data === "string") {
      try {
        data = JSON.parse(e.data)
      } catch (error) {
        console.warn(error)
      }
    }

    const primerData = data?.__primer_web_sdk__
    if (primerData?.type === "ACTION.INPUT_FOCUS") {
      const source = primerData.meta.source
      fire("form.field.focusin", { source })
    }
    if (primerData?.type === "ACTION.INPUT_BLUR") {
      const source = primerData.meta.source
      fire("form.field.focusout", { source })
    }
  })

  const listenerOptions = { capture: true }
  const subscriptionContainer = document.body
  subscriptionContainer.addEventListener(
    "click",
    (e) => {
      /* @ts-expect-error todo fix */
      const elementId = e?.target?.id ?? ""

      if (elementId === "primer-checkout-submit-button") {
        const invalidFieldsCount = document
          .querySelectorAll(".PrimerCheckout__helperText.PrimerCheckout--entered")
          .length.toString()
        fire("form.click", { button: "submit", invalidFieldsCount })
      } else if (
        elementId === "primer-checkout-apm-paypal" ||
        elementId === "primer-checkout-apm-primerTestPaypal"
      ) {
        fire("form.click", { button: "paypal" })
      }
    },
    listenerOptions
  )
  subscriptionContainer.addEventListener(
    "focusin",
    (e) => {
      /* @ts-expect-error todo fix */
      const elementId = e?.target?.id ?? ""

      if (elementId === "primer-checkout-card-cardholder-name-input") {
        fire("form.field.focusin", { source: "cardholder-name" })
      }
    },
    listenerOptions
  )
  subscriptionContainer.addEventListener(
    "focusout",
    (e) => {
      /* @ts-expect-error todo fix */
      const elementId = e?.target?.id ?? ""

      if (elementId === "primer-checkout-card-cardholder-name-input") {
        fire("form.field.focusout", { source: "cardholder-name" })
      }
    },
    listenerOptions
  )

  isSubscribed = true
}

type ShowFormArgs = FormArguments & {
  formType: FormType
  subscribe?: (event: PaymentFormEvent, params: any) => void
  container: HTMLElement
  paypalContainer?: HTMLElement
  applepayContainer?: HTMLElement
  theme: Theme
  variant?: FormVariant
}
const showFormInternal = async ({
  formType,
  priceId,
  userId,
  countryCode,
  emailAddress,
  subscribe: fire = noop,
  container,
  paypalContainer,
  applepayContainer,
  theme,
  variant,
}: ShowFormArgs) => {
  if (!isSubscribed) {
    globalEvents(fire)
  }

  const paymentFormSettings = {
    platformCode: "mobile_web",
    ident: priceId,
    countryCode,
    orderId: uuid(),
    customerId: { type: "merchant-str", value: userId },
    customer: { emailAddress, billingAddress },
  } as const

  const onEntering = (sceneId: string) => {
    fire("form.scene.change", { sceneId })
  }

  fire("form.init", { priceId })
  const client: PaymentClient = createPaymentClient(
    clientSettings({
      formType,
      onPaymentStatusChange: (status) => {
        fire("payment.change", { status })
      },
      onError: (error, description) => {
        Sentry.captureException(error)
        fire("payment.error", { error, description })
      },
    })
  )

  try {
    clearContainer(container)
    applepayContainer && clearContainer(applepayContainer)
    paypalContainer && clearContainer(paypalContainer)
    await client.showPaymentForm(
      paymentFormSettings,
      paymentFormOptions({
        container,
        applepayContainer,
        paypalContainer,
        onEntering,
        theme,
        variant,
      })
    )

    fire("form.init.success", { priceId })
  } catch (error) {
    Sentry.captureException(error)
    fire("form.init.fail", { priceId, error })
    throw error
  }

  fire("form.init.complete", { priceId })
}

const { append } = serialQueue(showFormInternal)
export const showPaltaPaymentsForm = (opts: ShowFormArgs) => append(opts)
