import { PeriodUnit } from "./PeriodUnit"
import { makePlurals } from "./plurals"

const monthsPlurals = makePlurals(["month", "months"])
const weeksPlurals = makePlurals(["week", "weeks"])
const daysPlurals = makePlurals(["day", "days"])
const monthsPluralsPrefix = makePlurals(["%% month", "%% months"])
const weeksPluralsPrefix = makePlurals(["%% week", "%% weeks"])
const daysPluralsPrefix = makePlurals(["%% day", "%% days"])
const monthsPluralsPrefixDash = makePlurals(["%%-month", "%%-months"])
const weeksPluralsPrefixDash = makePlurals(["%%-week", "%%-weeks"])
const daysPluralsPrefixDash = makePlurals(["%%-day", "%%-days"])
const unknownPlurals = makePlurals(["", ""])

export const formatLengthWithUnit = (trialUnit: PeriodUnit, trialLength: number): string => {
  switch (trialUnit) {
    case PeriodUnit.PERIOD_UNIT_MONTH:
      return monthsPlurals(trialLength)
    case PeriodUnit.PERIOD_UNIT_WEEK:
      return weeksPlurals(trialLength)
    case PeriodUnit.PERIOD_UNIT_DAY:
      return daysPlurals(trialLength)
    default:
      return unknownPlurals(trialLength)
  }
}

export const formatLengthWithUnit2 = (trialUnit: PeriodUnit, trialLength: number): string =>
  trialLength > 1
    ? formatUnitWithPrefixLength(trialUnit, trialLength)
    : formatLengthWithUnit(trialUnit, trialLength)

export const formatUnitWithPrefixLength = (trialUnit: PeriodUnit, trialLength: number): string => {
  switch (trialUnit) {
    case PeriodUnit.PERIOD_UNIT_MONTH:
      return monthsPluralsPrefix(trialLength)
    case PeriodUnit.PERIOD_UNIT_WEEK:
      return weeksPluralsPrefix(trialLength)
    case PeriodUnit.PERIOD_UNIT_DAY:
      return daysPluralsPrefix(trialLength)
    default:
      return unknownPlurals(trialLength)
  }
}

export const formatUnitWithPrefixLengthDash = (
  trialUnit: PeriodUnit,
  trialLength: number
): string => {
  switch (trialUnit) {
    case PeriodUnit.PERIOD_UNIT_MONTH:
      return monthsPluralsPrefixDash(trialLength)
    case PeriodUnit.PERIOD_UNIT_WEEK:
      return weeksPluralsPrefixDash(trialLength)
    case PeriodUnit.PERIOD_UNIT_DAY:
      return daysPluralsPrefixDash(trialLength)
    default:
      return unknownPlurals(trialLength)
  }
}
