import React, { FC, useCallback, useEffect, useRef } from "react"
import { Box, BoxProps, Button, ButtonProps, LinkProps, keyframes } from "@chakra-ui/react"

import { PRICE_SELECT_ID } from "~/constants"
import { TextBelowPriceSelectContainer } from "~/components/PaymentTexts"
import { useBlockProperties, usePriceSectionTitle } from "~/store/selectors"
import { useAmplitude } from "~/utils/analytics/useAmplitude"

import { useScrollToOffer } from "../scrollToOffer"
import { BlockPropertyContent, ChildrenIfBlockPropertyExist, Link, VFlex } from "~/components"
import {
  GoalAndDateContainer,
  PriceSelectRadioContainer,
  TimerNoticeContainer,
  MailChips,
  type PriceSelectVariants,
  usePriceSelect,
} from "../PriceSelectWithPaymentForm"

import { DiscountTextContainer } from "./UpsaleSubscriptionOnExitBlock"
import { BlockHeading, BlockWrapper, BlockWrapperProps } from "./BlockWrapper"
import { isApplePayAvailable } from "~/utils"

/* TODO: cleanup here */

const PriceSelectRadioContainerWrapper: FC<{
  priceSelectVariant: PriceSelectVariants
}> = ({ priceSelectVariant }) => {
  const { selectedPrice } = usePriceSelect()
  if (!selectedPrice) {
    return null
  }

  return <PriceSelectRadioContainer variant={priceSelectVariant} />
}

const PriceActionButton: FC<ButtonProps> = (props) => {
  const scrollToOffer = useScrollToOffer("invoice_action_button")
  const log = useAmplitude()
  const onClickFn = useCallback(() => {
    log.click({ source: "invoice_action_button" })
    scrollToOffer()
  }, [log, scrollToOffer])

  return <Button variant="action" w="full" onClick={onClickFn} {...props} />
}

const PriceActionLink: FC<LinkProps> = (props) => {
  const scrollToOffer = useScrollToOffer("price_action_link")
  const log = useAmplitude()
  const onClickFn = useCallback(() => {
    log.click({ source: "price_action_link" })
    scrollToOffer({ scroll: false, openModal: true })
  }, [log, scrollToOffer])

  return (
    <Link
      display="inline-block"
      w="full"
      textAlign="center"
      textStyle="Subtitle/Tertiary"
      textDecoration="underline"
      color="Base/basePrimary"
      onClick={onClickFn}
      {...props}
    />
  )
}

const MoneyBackLink: FC<{ propKey: string } & LinkProps> = ({ propKey, ...props }) => (
  <ChildrenIfBlockPropertyExist propKey={propKey}>
    <Link
      display="inline-block"
      href="https://lovi.care/money-back/"
      target="new"
      textDecoration="underline"
      textStyle="Subtitle/Tertiary"
      color="Base/basePrimary"
      {...props}
    >
      <BlockPropertyContent propKey={propKey}>default</BlockPropertyContent>
    </Link>
  </ChildrenIfBlockPropertyExist>
)

const dotsKeyframes = keyframes`
0%,
20% {
  color: rgba(0,0,0,0);
  text-shadow: .25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0);
}
40% {
  color: white;
  text-shadow: .25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0);}
60% { text-shadow: .25em 0 0 white, .5em 0 0 rgba(0,0,0,0);}

80%,
100% { text-shadow: .25em 0 0 white, .5em 0 0 white;}
}`
const dotsAnimation = `${dotsKeyframes} 1s steps(5, end) infinite`

const PriceActions: FC<{ blockPropertiesKey: string }> = ({ blockPropertiesKey }) => {
  const blockProperties = useBlockProperties()
  const renderSeparateApplePay =
    isApplePayAvailable() && Boolean(blockProperties[`${blockPropertiesKey}.applepay_button`] ?? "")

  return (
    <VFlex gap={6} mt={4} w="full" alignItems="center">
      <MoneyBackLink propKey={`${blockPropertiesKey}.money_back_link`} />

      {!renderSeparateApplePay && (
        <PriceActionButton>
          <BlockPropertyContent propKey={`${blockPropertiesKey}.button_text`}>
            Get The Program
          </BlockPropertyContent>
        </PriceActionButton>
      )}

      {renderSeparateApplePay && (
        <>
          <Box w="full" id="applepay-page-placeholder">
            <Box
              w="full"
              id="applepay-container"
              h={10}
              maxH={10}
              _empty={{
                bgColor: "black",
                fontSize: "24px",
                lineHeight: "24px",
                textAlign: "center",
                borderRadius: 4,
                animation: dotsAnimation,
                ":after": { content: "'.'" },
              }}
            ></Box>
          </Box>
          <PriceActionLink>Continue with other payment options</PriceActionLink>
        </>
      )}
    </VFlex>
  )
}

const BasePriceSelect2Content: FC<{
  priceSelectVariant: PriceSelectVariants
  blockPropertiesKey: string
}> = ({ priceSelectVariant, blockPropertiesKey }) => {
  const blockProperties = useBlockProperties()
  const mailChipsVariant = blockProperties[`${blockPropertiesKey}.mail_chips`] ?? "none"
  const timerNoticeVariant = blockProperties[`${blockPropertiesKey}.timer_notice`] ?? "none"

  return (
    <>
      <MailChips
        variant={mailChipsVariant}
        colorVariant="grey"
        paddingY={4}
        marginLeft={-6}
        marginRight={-6}
        width="calc(100% + 24px + 24px)"
        borderBottom="1px"
        borderColor="Base/neutralSecondary"
      />

      <BlockHeading marginY={6}>
        <BlockPropertyContent propKey={`${blockPropertiesKey}.title`}>
          Get your program
        </BlockPropertyContent>
      </BlockHeading>

      <TimerNoticeContainer variant={timerNoticeVariant} mb={3} />

      <PriceSelectRadioContainerWrapper priceSelectVariant={priceSelectVariant} />

      <PriceActions blockPropertiesKey={blockPropertiesKey} />
    </>
  )
}

const BasePriceSelect4Content: FC<{
  priceSelectVariant: PriceSelectVariants
  blockPropertiesKey: string
}> = ({ priceSelectVariant, blockPropertiesKey }) => {
  const { selectedPrice } = usePriceSelect()
  const blockProperties = useBlockProperties()
  const mailChipsVariant = blockProperties[`${blockPropertiesKey}.mail_chips`] ?? "none"
  const timerNoticeVariant = blockProperties[`${blockPropertiesKey}.timer_notice`] ?? "none"

  if (!selectedPrice) {
    return null
  }

  return (
    <>
      <MailChips
        variant={mailChipsVariant}
        colorVariant="white"
        marginLeft={-6}
        marginRight={-6}
        width="calc(100% + 24px + 24px)"
        paddingTop={0}
        paddingBottom={8}
      />

      <DiscountTextContainer />

      <BlockHeading>
        <BlockPropertyContent propKey={`${blockPropertiesKey}.title`}>
          {`Choose your program\nto get started`}
        </BlockPropertyContent>
      </BlockHeading>

      <TimerNoticeContainer variant={timerNoticeVariant} mb={3} />

      <PriceSelectRadioContainerWrapper priceSelectVariant={priceSelectVariant} />

      <PriceActions blockPropertiesKey={blockPropertiesKey} />
    </>
  )
}

export const PriceSelect2Block: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper id={PRICE_SELECT_ID} paddingTop={0} {...props}>
    <BasePriceSelect2Content priceSelectVariant={2} blockPropertiesKey="PB_PRICE_SELECT_2" />
  </BlockWrapper>
)

export const PriceSelect3Block: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper id={PRICE_SELECT_ID} paddingTop={0} {...props}>
    <BasePriceSelect2Content priceSelectVariant={3} blockPropertiesKey="PB_PRICE_SELECT_3" />
  </BlockWrapper>
)

export const PriceSelect4Block: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper id={PRICE_SELECT_ID} marginY={4} paddingX={6} variant="transparent" {...props}>
    <BasePriceSelect2Content priceSelectVariant={4} blockPropertiesKey="PB_PRICE_SELECT_4" />
  </BlockWrapper>
)

export const PriceSelect4_1Block: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper id={PRICE_SELECT_ID} marginY={6} paddingX={6} variant="transparent" {...props}>
    <BasePriceSelect4Content priceSelectVariant={4} blockPropertiesKey="PB_PRICE_SELECT_4_1" />
  </BlockWrapper>
)

export const PriceSelect4_2Block: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper id={PRICE_SELECT_ID} marginY={6} paddingX={6} variant="transparent" {...props}>
    <BasePriceSelect4Content priceSelectVariant={4} blockPropertiesKey="PB_PRICE_SELECT_4_2" />
  </BlockWrapper>
)

export const PriceSelect5Block: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper id={PRICE_SELECT_ID} marginY={6} paddingX={6} variant="transparent" {...props}>
    <BasePriceSelect4Content priceSelectVariant={5} blockPropertiesKey="PB_PRICE_SELECT_5" />
  </BlockWrapper>
)

export const PriceSelect5_2Block: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper id={PRICE_SELECT_ID} marginY={6} paddingX={6} variant="transparent" {...props}>
    <BasePriceSelect4Content priceSelectVariant={5} blockPropertiesKey="PB_PRICE_SELECT_5_2" />
  </BlockWrapper>
)

export const PriceSelect6Block: FC<BlockWrapperProps> = (props) => {
  return (
    <BlockWrapper id={PRICE_SELECT_ID} paddingTop={0} {...props}>
      <BasePriceSelect2Content priceSelectVariant={6} blockPropertiesKey="PB_PRICE_SELECT_6" />
    </BlockWrapper>
  )
}

export const PriceSelect6_2Block: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper id={PRICE_SELECT_ID} paddingTop={0} {...props}>
    <BasePriceSelect2Content priceSelectVariant={6} blockPropertiesKey="PB_PRICE_SELECT_6_2" />
  </BlockWrapper>
)

export const PriceSelect7Block: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper id={PRICE_SELECT_ID} paddingTop={0} {...props}>
    <BasePriceSelect2Content priceSelectVariant={7} blockPropertiesKey="PB_PRICE_SELECT_7" />
  </BlockWrapper>
)

export const PriceSelect8Block: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper id={PRICE_SELECT_ID} marginY={6} paddingX={6} variant="transparent" {...props}>
    <BasePriceSelect2Content priceSelectVariant={8} blockPropertiesKey="PB_PRICE_SELECT_8" />
  </BlockWrapper>
)

export const PriceSelectWithDateBlock: FC<BlockWrapperProps> = (props) => {
  const header = usePriceSectionTitle() ?? "Get your program"
  return (
    <BlockWrapper id={PRICE_SELECT_ID} {...props}>
      <BlockHeading size="Header/Primary">{header}</BlockHeading>
      <GoalAndDateContainer mb={6} mt={2} />
      <PriceSelectRadioContainerWrapper priceSelectVariant={2} />
      <PriceActionButton mt={6}>Get The Program</PriceActionButton>
      <TextBelowPriceSelectContainer mt={6} />
    </BlockWrapper>
  )
}

export const PriceSelectWithDate2Block: FC<BlockWrapperProps> = (props) => {
  return (
    <BlockWrapper id={PRICE_SELECT_ID} {...props}>
      <BlockHeading>Get visible results in 4 weeks!</BlockHeading>
      <GoalAndDateContainer mb={6} mt={2} />
      <PriceSelectRadioContainerWrapper priceSelectVariant={2} />
      <PriceActionButton mt={6}>Get The Program</PriceActionButton>
      <TextBelowPriceSelectContainer mt={6} />
    </BlockWrapper>
  )
}

export const PriceSelectWithDate3Block: FC<BlockWrapperProps> = (props) => {
  const header = usePriceSectionTitle() ?? "Get your program"
  return (
    <BlockWrapper id={PRICE_SELECT_ID} {...props}>
      <BlockHeading size="Header/Primary">{header}</BlockHeading>
      <GoalAndDateContainer mb={6} mt={2} />
      <PriceSelectRadioContainerWrapper priceSelectVariant={3} />
      <PriceActionButton mt={6}>Get The Program</PriceActionButton>
    </BlockWrapper>
  )
}

export const PriceSelectWithDate4Block: FC<BlockWrapperProps> = (props) => {
  return (
    <BlockWrapper id={PRICE_SELECT_ID} {...props}>
      <BlockHeading>Get visible results in 4 weeks!</BlockHeading>
      <GoalAndDateContainer mb={6} mt={2} />
      <PriceSelectRadioContainerWrapper priceSelectVariant={3} />
      <PriceActionButton mt={6}>Get The Program</PriceActionButton>
    </BlockWrapper>
  )
}
