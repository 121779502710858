import React, { FC } from "react"
import { Button, ButtonProps } from "@chakra-ui/react"

import { ReactComponent as ApplePayLogo } from "../applepay-logo.svg"

export const ApplePayButton: FC<ButtonProps> = (props) => (
  <Button variant="applepay" h="auto" {...props}>
    Continue with&nbsp;
    <ApplePayLogo />
  </Button>
)
