import * as Sentry from "@sentry/react"
import React, { FC, useEffect } from "react"
import { BrowserRouter as Router, Outlet, Route, Routes } from "react-router-dom"
import { Main } from "./pages/Main"
import { useDownloadData } from "~/pages/Main/useDownloadData"
import { Processing } from "./pages/Processing"
import { AppLinkPage } from "~/pages/AppLinkPage"
import { useAmplitude } from "~/utils/analytics/useAmplitude"
import { useAuthInfo } from "~/utils/useAuthInfo"
import { useNavigateWithSearchParams } from "~/utils/useNavigateWithSearchParams"
import { useBlockProperties, useIsAiScanFlowV2, useIsContentLoaded } from "~/store/selectors"
import { PreloadedStatic } from "~/utils/static_images_to_preload"

const Redirect: FC<{ to: string }> = ({ to }) => {
  const nav = useNavigateWithSearchParams()

  useEffect(() => {
    nav(to)
  }, [nav, to])

  return null
}

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)
const getLink = (link: string) => <link key={link} href={link} rel="preload" as="image" />

const Prefetch: FC = () => <>{Object.values(PreloadedStatic).map(getLink)}</>

const DataLoader: FC = () => {
  useDownloadData()
  const isContentLoaded = useIsContentLoaded()
  const isAiScanFlowV2 = useIsAiScanFlowV2()
  const blockProperties = useBlockProperties()
  const globalCss = blockProperties["GLOBAL.css"]

  if (isContentLoaded) {
    return (
      <>
        <Outlet />
        {isAiScanFlowV2 && <Prefetch />}
        {globalCss && <style dangerouslySetInnerHTML={{ __html: globalCss }} />}
      </>
    )
  }

  return null
}

export const App: FC = () => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuthInfo()
  const log = useAmplitude()

  useEffect(() => {
    log.init()
  }, [log])

  if (isLoading) {
    return null // Loader in html
  }

  if (!isAuthenticated) {
    loginWithRedirect() // TODO move to routes
  }

  return (
    <Router>
      <SentryRoutes>
        <Route path="/" element={<DataLoader />}>
          <Route path="pay" element={<Main />} />
          <Route path="processing" element={<Processing />} />
          <Route path="app" element={<AppLinkPage />} />

          <Route index element={<Redirect to="/pay/" />} />
        </Route>
      </SentryRoutes>
    </Router>
  )
}
