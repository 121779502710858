import React, { useState } from "react"
import { Flex } from "@chakra-ui/react"
import { useSearchParams } from "react-router-dom"
import { nullAsUndefined } from "~/utils"
import { SuccessScreenVariant } from "~/types"
import { DefaultVariant } from "./DefaultVariant/DefaultVariant"
import { AlternativeVariant } from "./AlternativeVariant"

const SUPPORTED_VARIANTS: SuccessScreenVariant[] = [
  "default",
  "workbookSuccess",
  "workbookFail",
  "aiScanSuccess",
  "aiScanFail",
  "bundleSuccess",
  "bundleFail",
]

export const AppLinkPage = () => {
  const [searchParams] = useSearchParams()
  const _variant = nullAsUndefined(searchParams.get("variant") as SuccessScreenVariant) ?? "default"
  const [variant, setVariant] = useState(_variant)
  const isUnsupportedVariant = !SUPPORTED_VARIANTS.includes(variant)

  return (
    <Flex
      direction="column"
      w="full"
      minH="$100vh"
      alignItems="center"
      bgColor="Base/neutralSecondary"
    >
      {variant === "default" || isUnsupportedVariant ? (
        <DefaultVariant />
      ) : (
        <AlternativeVariant variant={variant} setDefaultVariant={() => setVariant("default")} />
      )}
    </Flex>
  )
}
