import React, { FC, useCallback } from "react"
import { Box, Button, Heading, Text, useDisclosure } from "@chakra-ui/react"

import { PAYMENT_FORM_ID } from "~/constants"
import { TextBelowPaymentFormContainer } from "~/components/PaymentTexts"
import { usePayButtonTitle, usePriceSectionTitle, useSelectedPrice } from "~/store/selectors"
import { useAmplitude } from "~/utils/analytics/useAmplitude"
import { useMetaPixel } from "~/utils/analytics/useMetaPixel"
import { useTiktokPixel } from "~/utils/analytics/useTiktokPixel"
import type { PriceContentV2_PriceItem } from "~/generated/paywall"
import { PaymentFormContainer } from "../PaymentForm"

import type { PriceId } from "./types"
import { PriceSelectRadioContainer } from "./PriceSelectRadio"
import { PaymentFormPopupContainer } from "./PaymentFormPopupContainer"

const SubscriptionText: FC<{ price: PriceContentV2_PriceItem | undefined }> = ({ price }) => {
  const text = price?.subscription_text
  if (!text) {
    return null
  }

  return (
    <Text textStyle="Subtitle/Hint" color="Base/baseSecondary" marginTop={4}>
      {text}
    </Text>
  )
}

const PaymentFormWithTextContainer = () => (
  <>
    <PaymentFormContainer />
    <TextBelowPaymentFormContainer mt={3} />
  </>
)

export const PriceSelectWithPaymentForm: FC<{ withPopup: boolean }> = ({ withPopup }) => {
  const title = usePriceSectionTitle() ?? "Get your program"
  const buttonTitle = usePayButtonTitle() ?? "Get The Program"

  const { isOpen, onOpen, onClose } = useDisclosure()
  const selectedPrice = useSelectedPrice()

  const log = useAmplitude()
  const logMp = useMetaPixel()
  const logTtp = useTiktokPixel()

  const openPaymentForm = useCallback(() => {
    if (selectedPrice) {
      log.paymentFormShow()
      const productInfo = {
        priceId: selectedPrice.id,
        value: selectedPrice.subscription_price_cents,
        currency: selectedPrice.currency_code,
      }
      logMp.initiateCheckout(productInfo)
      logTtp.initiateCheckout(productInfo)
      onOpen()
    }
  }, [logMp, logTtp, log, onOpen, selectedPrice])

  const onClickAction = useCallback(() => {
    // todo fix
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    log.clickActionButton({ priceId: selectedPrice?.id! })
    openPaymentForm()
  }, [log, openPaymentForm, selectedPrice?.id])

  const onSelectPrice = useCallback(() => {
    openPaymentForm()
  }, [openPaymentForm])

  if (!selectedPrice) {
    return null
  }

  const FormImplementation = withPopup ? PaymentFormPopupContainer : PaymentFormWithTextContainer

  return (
    <Box w="full">
      <Heading size="Header/Secondary" marginBottom={4} textAlign="center">
        {title}
      </Heading>

      <PriceSelectRadioContainer onChange={onSelectPrice} variant={1} />

      <Box w="full" marginTop={6} id={PAYMENT_FORM_ID}>
        {!isOpen && (
          <Button variant="action" w="full" onClick={onClickAction}>
            {buttonTitle}
          </Button>
        )}

        <FormImplementation hidden={!isOpen} onClose={onClose} />

        <SubscriptionText price={selectedPrice} />
      </Box>
    </Box>
  )
}
