import React, { FC, useEffect } from "react"
import { Timer, useTimerContext } from "~/components"
import {
  useBlockProperties,
  useCountdownData,
  useIsPaymentFormInPopup,
  useSpecialOfferOpts,
} from "~/store/selectors"

import { useFloatTimerLogic } from "./useFloatTimerLogic"
import { TimerFloat, TimerFloatVariant } from "./TimerFloat"

export const TimerFloatContainer: FC<{ variant?: TimerFloatVariant }> = ({ variant }) => {
  const { text, button_text, display_timeout, await_scroll } = useSpecialOfferOpts()
  const blockProperties = useBlockProperties()
  const buttonText = blockProperties["TIMER_FLOAT.button_text"] ?? button_text ?? "Start the plan"
  const displayTimeout = parseFloat(
    String(blockProperties["TIMER_FLOAT.display_timeout"] ?? display_timeout ?? 3)
  )
  let awaitScroll = await_scroll ?? true
  if (typeof blockProperties["TIMER_FLOAT.await_scroll"] !== undefined) {
    awaitScroll = blockProperties["TIMER_FLOAT.await_scroll"]?.toLowerCase() === "true"
  }

  const isPaymentFormInPopup = useIsPaymentFormInPopup()
  const { isOpenOffer, onClickSpecialOffer } = useFloatTimerLogic({
    display_timeout: displayTimeout,
    await_scroll: awaitScroll,
    close_after_action: !isPaymentFormInPopup,
  })

  const { minutes = 0 } = useCountdownData()
  const { start, stop } = useTimerContext()
  useEffect(() => {
    start(minutes * 60)
    return () => {
      stop()
    }
  }, [])

  return (
    <TimerFloat
      variant={variant}
      timer={<Timer />}
      visible={isOpenOffer}
      onClick={onClickSpecialOffer}
      offerTitle={text}
      buttonText={buttonText}
    />
  )
}
