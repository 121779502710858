const getEnv = <T extends string | undefined>(envName: string, def: T): T => {
  return (import.meta.env[envName] as T) ?? def
}

const defaultConfig = {
  endpoints: {
    getPaywallData: getEnv(
      "REACT_APP_GET_PAYWALL_DATA_API",
      "https://api.pora.ai/interview/paywall_v2"
    ),
    getCountryCode: getEnv("REACT_APP_GET_COUNTRY_CODE_API", "https://country.pora.ai/"),
    createStripeIntent: getEnv(
      "REACT_APP_CREATE_STRIPE_INTENT_API",
      "https://api.pora.ai/stripe/create-payment-intent"
    ),
    createSkipTrialStripeIntent: getEnv(
      "REACT_APP_CREATE_SKIP_TRIAL_STRIPE_INTENT_API",
      "https://api.pora.ai/stripe/upgrade-subscription"
    ),
    buyWorkbookPalta: getEnv(
      "REACT_APP_BUY_WORKBOOK_PALTA_API",
      "https://api.pora.ai/palta-payments/buy-workbook"
    ),
    makePurchasePalta: getEnv(
      "REACT_APP_MAKE_PURCHASE_PALTA_API",
      "https://api.pora.ai/palta-payments/purchase"
    ),
    savePaymentData: getEnv("REACT_APP_SAVE_PAYMENT_DATA", "https://api.pora.ai/payments"),
    log: getEnv("REACT_APP_LOG_API", "https://api.pora.ai/counters/webhook"),
  },
  payments: {
    api: getEnv("REACT_APP_PAYMENTS_API", "https://api.payments.pora.paltabrain.com"),
    key: getEnv("REACT_APP_PAYMENTS_KEY", "037ebbdfc3d44087b196e9d2c25251d2"),
    workflow: getEnv("REACT_APP_PAYMENTS_WORKFLOW", "Skincare"),
  },
  stripe: {
    key: getEnv(
      "REACT_APP_STRIPE_PUBLISHABLE_KEY",
      "pk_live_51JxzjSFHfJrkKA96fTuZo5ROVNwQaJp6qUGcCHxszNznpCQdElYziwDOkWZ8ydX5CaPvfLajBGN36E0McX66wr3F004Zy061TV"
    ),
  },
  paypal: {
    clientId: getEnv(
      "REACT_APP_PAYPAL_CLIENT_ID",
      "ARDd5fj2h1xcgAiwJFAl7UVijhUfE2-K_yv8jaDZcJxk1O_fc0eFSTibNXJZNz3_D1tRnmWke7SNeXoT"
    ),
  },
  auth: {
    domain: getEnv("REACT_APP_AUTH0_DOMAIN", "auth.pora.ai"),
    clientId: getEnv("REACT_APP_AUTH0_CLIENT_ID", "6IxlFiYT8fgZI4CT2tuPGkijkSuIaJ1d"),
  },
  sentry: {
    dsn: getEnv("REACT_APP_SENTRY_DSN", undefined),
  },
  theme: getEnv<"pora" | "lovi">("REACT_APP_THEME", "pora"),
  environment:
    (import.meta.env["REACT_APP_ENVIRONMENT"] as "development") ??
    (window.location.href.toString().includes("test") ? "testing" : "production"),
} as const

export const CONFIG = defaultConfig
