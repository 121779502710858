import { Ref, useCallback } from "react"
import { useInView } from "react-intersection-observer"
import { useAmplitude } from "~/utils/analytics/useAmplitude"

export function useTrackBlockVisibility<T extends Element>(analyticsId: string): Ref<T> {
  const { observeBlockVisibility } = useAmplitude()
  const onChangeVisibility = useCallback(
    (inView: boolean, entry: IntersectionObserverEntry) => {
      if (analyticsId) {
        observeBlockVisibility({
          block: analyticsId,
          visible: inView,
          visibility_ratio: entry.intersectionRatio.toFixed(1),
        })
      }
    },
    [observeBlockVisibility, analyticsId]
  )

  const { ref } = useInView({
    threshold: [0, 0.5, 1],
    onChange: onChangeVisibility,
    fallbackInView: false,
  })

  return ref
}
