import { configureStore, createReducer } from "@reduxjs/toolkit"
import type { PaywallV2 } from "../generated/paywall"
import type { CountryCode } from "../types"
import {
  setCountryCode,
  setLoadingPage,
  paymentFormPopupSetIsOpen,
  setPaywallData,
  setPrice,
  upsaleSubscriptionOnExitSetActive,
  upsaleSubscriptionOnExitSetPopupOpen,
  updatePaymentStatus,
} from "./actions"

export const PAYMENT_STATUSES = {
  NOT_READY: "not-ready",
  READY: "ready",
  FETCHING: "fetching",
  ERROR: "error",
  PENDING: "pending",
  SUCCESS: "success",
  FAIL: "fail",
} as const

export type PAYMENT_STATUSES_VALUES = (typeof PAYMENT_STATUSES)[keyof typeof PAYMENT_STATUSES]
/** @deprecated
 * FIXME: use PaymentProviders from paywall.proto
 */
export type PAYMENT_PROVIDER = "PAYPAL" | "STRIPE" | "PALTA" | "UNKNOWN"

export type State = {
  data: PaywallV2 | undefined
  payment: {
    status: PAYMENT_STATUSES_VALUES
    provider: PAYMENT_PROVIDER
  }
  isLoadingPageOpen: boolean
  country: CountryCode | undefined
  selectedPriceId: string | undefined
  paymentFormPopup: {
    isOpen: boolean
  }
  subscriptionOnExitFlow: {
    active: boolean
    popupIsOpen: boolean
  }
}

const initState: State = {
  data: undefined,
  payment: {
    status: PAYMENT_STATUSES.NOT_READY,
    provider: "UNKNOWN",
  },
  isLoadingPageOpen: false,
  country: undefined,
  selectedPriceId: undefined,
  paymentFormPopup: {
    isOpen: false,
  },
  subscriptionOnExitFlow: {
    active: false,
    popupIsOpen: false,
  },
}

const reducer = createReducer(initState, (builder) => {
  builder.addCase(setLoadingPage, (state, action) => {
    state.isLoadingPageOpen = action.payload
  })

  builder.addCase(updatePaymentStatus, (state, action) => {
    const { status, provider } = action.payload
    state.payment = { status, provider }
  })

  builder.addCase(setPaywallData, (state, action) => {
    state.data = action.payload
  })

  builder.addCase(setPrice, (state, action) => {
    state.selectedPriceId = action.payload
  })

  builder.addCase(setCountryCode, (state, action) => {
    state.country = action.payload
  })

  builder.addCase(paymentFormPopupSetIsOpen, (state, action) => {
    state.paymentFormPopup.isOpen = action.payload
  })

  builder.addCase(upsaleSubscriptionOnExitSetActive, (state, action) => {
    state.subscriptionOnExitFlow.active = action.payload
    // update selected price by index
    const prices = state.data?.price?.priceses ?? []
    const upsalePrices =
      state.data?.properties?.upsale_subscription_on_exit_properties?.prices_discounted ?? []
    const selectedPriceIdx = prices.findIndex((price) => price.id === state.selectedPriceId)
    if (upsalePrices && selectedPriceIdx > -1) {
      const newPriceIdx = upsalePrices.findIndex((price) => price.id === state.selectedPriceId)
      if (newPriceIdx > -1) {
        return
      }
      if (upsalePrices[selectedPriceIdx]) {
        state.selectedPriceId = upsalePrices[selectedPriceIdx]?.id
      }
      state.selectedPriceId = upsalePrices.at(-1)?.id
    }
  })

  builder.addCase(upsaleSubscriptionOnExitSetPopupOpen, (state, action) => {
    state.subscriptionOnExitFlow.popupIsOpen = action.payload
  })
})

export const store = configureStore({ reducer })
export const getPreloadedStore = (preloadedState: State) =>
  configureStore({ reducer, preloadedState })

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
