import React, { FC } from "react"
import { Button, Heading, Text, Image, Flex } from "@chakra-ui/react"
import { SuccessScreenVariant } from "~/types"
import { ButtonSlideUpContainer } from "~/components/ButtonSlideUpContainer"
import iconSuccess from "./assets/icon-success.svg?url"
import iconFailure from "./assets/icon-failure.svg?url"

type AlternativeVariant = {
  variant: Exclude<SuccessScreenVariant, "default">
  setDefaultVariant: () => void
}

const variantMap: Record<
  AlternativeVariant["variant"],
  {
    title: string
    description: JSX.Element
    icon: string
  }
> = {
  workbookSuccess: {
    title: "Congratulations!",
    description: (
      <span>
        Lóvi Workbook is sent to your email.
        <br />
        Let&apos;s start your personal skincare journey in the Lóvi app!
      </span>
    ),
    icon: iconSuccess,
  },
  workbookFail: {
    title: "Workbook wasn't purchased",
    description: <span>But let&apos;s start your personal skincare journey in Lóvi app!</span>,
    icon: iconFailure,
  },
  aiScanSuccess: {
    title: "Success",
    description: (
      <span>
        AI Product Scan is now unlocked in the Lovi App and the Workbook is in your email.
        <br />
        Open the app and start your personal skincare journey!
      </span>
    ),
    icon: iconSuccess,
  },
  aiScanFail: {
    title: "No pressure!",
    description: (
      <span>
        A special gift: enjoy <b>6 months free</b> of AI Product Scan access! Begin your skincare
        journey at your own pace with the Lovi App.
      </span>
    ),
    icon: iconFailure,
  },
  bundleSuccess: {
    title: "Success",
    description: (
      <span>
        AI Product Scan and AI Assistant are now unlocked in the Lovi App and the Workbook is in
        your email.
        <br />
        Start skincare journey!
      </span>
    ),
    icon: iconSuccess,
  },
  bundleFail: {
    title: "No pressure!",
    description: (
      <span>
        A special gift: enjoy <b>6 months free</b> of AI Product Scan and AI Assistant access! Begin
        your skincare journey at your own pace with the Lovi App.
      </span>
    ),
    icon: iconFailure,
  },
}

export const AlternativeVariant: FC<AlternativeVariant> = ({ variant, setDefaultVariant }) => {
  const { title, description, icon } = variantMap[variant]

  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="center"
      minH="$100vh"
      paddingTop={10}
      paddingX={6}
    >
      <Image src={icon} maxWidth="60%" />
      <Heading marginTop={6} marginBottom={4} textAlign="center" size="Header/Primary">
        {title}
      </Heading>
      {/* margin bottom for Download button compensation */}
      <Text marginBottom={110} textAlign="center" maxWidth="85%">
        {description}
      </Text>
      <ButtonSlideUpContainer visible showGradient={false}>
        <Button onClick={setDefaultVariant} variant="next" w="full">
          Continue in the App
        </Button>
      </ButtonSlideUpContainer>
    </Flex>
  )
}
