import { useMemo } from "react"
import { sendLog } from "~/api/api"

const track = (action: string, values: Record<string, unknown> = {}) => {
  sendLog({ event: action, params: values })
}

const purchase = (values: Record<string, unknown>) => track("checkout", values)
const addToCart = (values: Record<string, unknown> = {}) => track("add_to_cart", values)
const pageView = () => track("page_view")

export const useInternalAnalytics = () =>
  useMemo(
    () => ({
      track,
      purchase,
      pageView,
      addToCart,
    }),
    []
  )
