import React, {
  FC,
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { Box, Flex, keyframes } from "@chakra-ui/react"
import { PaymentProviders } from "~/generated/paywall"
import { useCountry, useSelectedPrice, useUserEmail, useUserId } from "~/store/selectors"
import { useAmplitude } from "~/utils/analytics/useAmplitude"
import { nullAsUndefined, isApplePayAvailable } from "~/utils"
import { useCurrentTheme } from "~/theme"

import { PaymentFormEvent, showPaltaPaymentsForm } from "./PaltaPaymentFormV2"
import { PaymentProviderSwitch } from "./PaymentSwitch"
import { useOnPaymentStatusChange, type PaymentStatus } from "./useOnPaymentStatusChange"
import { InternalPaymentProviders, PaymentProvidersUnion } from "./InternalPaymentProviders"
import spinnerSvg from "./payment-spinner.svg?url"
import { insertBefore } from "./insertBefore"

const formEventToAnalytics =
  (log: ReturnType<typeof useAmplitude>) =>
  (event: PaymentFormEvent, params: Record<string, unknown>): void => {
    if (event === "form.click") {
      log.paymentFormButtonClick(params)
    } else if (event === "form.field.focusin") {
      log.paymentFormInputFocusIn(params)
    } else if (event === "form.field.focusout") {
      log.paymentFormInputFocusOut(params)
    } else if (event === "form.init") {
      log.paymentFormInitStarted(params)
    } else if (event === "form.init.success") {
      log.paymentFormShowSuccess(params)
    } else if (event === "form.init.fail") {
      log.paymentFormShowFail(params)
    } else if (event === "form.init.complete") {
      log.paymentFormSceneChange(params)
    } else if (event === "payment.change") {
      log.paymentStatusChange(params)
    } else if (event === "payment.error") {
      log.paymentError(params)
    }
  }

const rotateKeyframes = keyframes`
0% { rotate: 0; }
100% { rotate: 360deg; }
`

const rotateAnimation = `${rotateKeyframes} 1s linear infinite`
const FormLoadingSpinner: FC<{ externalRef: MutableRefObject<null> }> = ({ externalRef }) => (
  <Flex
    ref={externalRef}
    w="full"
    minW="250px"
    direction="column"
    justifyContent="center"
    sx={{
      "&:empty::before": {
        marginTop: "35px",
        marginBottom: "18px",
        backgroundImage: spinnerSvg,
        content: "' '",
        width: "27px",
        height: "27px",

        display: "inline-block",
        animation: rotateAnimation,
        alignSelf: "center",
      },
    }}
  />
)

const PaltaPaymentFormPlaceholder: FC<{
  paymentFormRef: MutableRefObject<null>
  selectedProvider: PaymentProvidersUnion
  children?: ReactNode
}> = ({ paymentFormRef, selectedProvider, children }) => (
  <Box
    sx={{
      "& #primer-checkout-apm-paypal": {
        height: "55px",
        display:
          selectedProvider === PaymentProviders.PAYMENT_PROVIDER_PALTA_PAYPAL ? "auto" : "none",
      },
      "& #primer-checkout-apm-applePay, & #applepay-modal-placeholder": {
        display: selectedProvider === InternalPaymentProviders.PALTA_APPLE_PAY ? "auto" : "none",
      },
      "& #primer-checkout-apm-applePay button": {
        ApplePayButtonType: "plain",
        borderRadius: "60px",
        height: "60px !important",
      },
      "& #primer-checkout-card-form": {
        display:
          selectedProvider === PaymentProviders.PAYMENT_PROVIDER_PALTA_CARD ? "auto" : "none",
      },
      "& #primer-checkout-submit-button-container": {
        height: "60px",
        display:
          selectedProvider === PaymentProviders.PAYMENT_PROVIDER_PALTA_CARD ? "auto" : "none",
      },
      "& #primer-checkout-submit-button": {
        opacity: 1,
        height: "60px",
      },
      "& .PrimerCheckout__apmButton:focus-within": {
        outline: "none",
      },
      "& .PrimerCheckout__sceneElement:not(:last-child)": {
        marginBottom: 0,
      },
      "#primer-checkout-other-payment-methods>*:last-child": {
        height: "24px !important",
      },
    }}
  >
    {children}
    <FormLoadingSpinner externalRef={paymentFormRef} />
  </Box>
)

const PaltaProviders: PaymentProvidersUnion[] = [
  PaymentProviders.PAYMENT_PROVIDER_PALTA_CARD,
  PaymentProviders.PAYMENT_PROVIDER_PALTA_PAYPAL,
]

if (isApplePayAvailable()) {
  insertBefore(PaltaProviders)(
    PaymentProviders.PAYMENT_PROVIDER_PALTA_CARD,
    InternalPaymentProviders.PALTA_APPLE_PAY
  )
}

export const PaymentFormPaltaPopupContainer: FC<{ children?: ReactNode }> = ({ children }) => {
  const paymentFormRef = useRef(null)

  const selectedPrice = useSelectedPrice()

  const log = useAmplitude()
  const theme = useCurrentTheme()

  const onPaymentComplete = useOnPaymentStatusChange()
  const formSubscribe = useCallback(
    (event: PaymentFormEvent, params: { status?: PaymentStatus }) => {
      formEventToAnalytics(log)(event, params)
      if (event === "payment.change") {
        const { status = "UNKNOWN" } = params
        onPaymentComplete("PALTA", status)
      }
    },
    [log, onPaymentComplete]
  )

  const [selectedProvider, setSelectedProvider] = useState<PaymentProvidersUnion>(
    PaymentProviders.PAYMENT_PROVIDER_PALTA_CARD
  )
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false)

  const userId = useUserId()
  const emailAddress = useUserEmail()
  const countryCode = useCountry()
  const updatePaymentForm = useCallback(
    (provider: PaymentProvidersUnion | undefined) => {
      if (paymentFormRef.current && selectedPrice) {
        if (
          provider === InternalPaymentProviders.PALTA_APPLE_PAY ||
          provider === PaymentProviders.PAYMENT_PROVIDER_PALTA_CARD ||
          provider === PaymentProviders.PAYMENT_PROVIDER_PALTA_PAYPAL
        ) {
          showPaltaPaymentsForm({
            formType: "card",
            // todo fix
            // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion
            priceId: selectedPrice.payment_providers?.palta_card?.price_id!,
            container: paymentFormRef.current,
            //paypalContainer: paypalRef.current,
            applepayContainer: nullAsUndefined(document.getElementById("applepay-container")),
            subscribe: formSubscribe,
            userId,
            countryCode,
            emailAddress,
            theme,
            variant: "PALTA_POPUP",
          }).finally(() => {
            setIsFormLoading(false)
          })
          setIsFormLoading(true)
        } else if (provider === InternalPaymentProviders.STRIPE_APPLE_PAY) {
          // Do Nothings
          // Same as PaymentProviders.PAYMENT_PROVIDER_STRIPE
        } else if (provider === PaymentProviders.PAYMENT_PROVIDER_STRIPE) {
          // Do Nothings
          // Show form on react side
        } else if (provider === PaymentProviders.PAYMENT_PROVIDER_PAYPAL) {
          // Do Nothings
          // Form generated by Paypal button
        } else {
          console.error(`Unknown provider ${provider}`)
        }
      }
    },
    [selectedPrice, formSubscribe, userId, countryCode, emailAddress, theme]
  )

  useEffect(() => {
    /* Change form type */
    updatePaymentForm(selectedProvider)
  }, [log, /* selectedProvider, */ updatePaymentForm])

  const onChangePaymentProvider = useCallback(
    (provider: PaymentProvidersUnion) => {
      setSelectedProvider(provider)
      log.paymentFormChangeType({ type: provider })
    },
    [setSelectedProvider, log]
  )

  return (
    <Flex direction="column" w="full" overflow="hidden">
      <PaymentProviderSwitch
        onChange={onChangePaymentProvider}
        selected={selectedProvider}
        isDisabled={isFormLoading}
        providers={PaltaProviders}
        height="64px"
      />
      <Flex direction="column" w="full" gap={4} pt="26px">
        {children}

        <PaltaPaymentFormPlaceholder
          paymentFormRef={paymentFormRef}
          selectedProvider={selectedProvider}
        >
          <Box id="applepay-modal-placeholder" w="full"></Box>
        </PaltaPaymentFormPlaceholder>
      </Flex>
    </Flex>
  )
}
