import React, { FC } from "react"
import { BlockWrapper, BlockWrapperProps } from "../BlockWrapper"
import { SubscriptionTextContainer, TextBox2 } from "~/components/StaticTexts"

export const SubscriptionInfoTextBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper {...props}>
    <TextBox2>
      <SubscriptionTextContainer />
    </TextBox2>
  </BlockWrapper>
)
