import { ComponentStyleConfig } from "@chakra-ui/react"
import { RadioAppButtonTheme } from "~/components/RadioAppButton"
import { PaymentSwitchButtonTheme } from "~/pages/Main/PaymentForm/PaymentSwitch"
import { ButtonTheme } from "~/shared/Button"
import { getTextStyles } from "./theme-from-interview/textStyles"

const Link: ComponentStyleConfig = {
  baseStyle: {
    color: "auto",
    _hover: {
      textDecoration: "none",
    },
    _active: {
      textDecoration: "none",
    },
  },
}

const poraTag: ComponentStyleConfig = {
  variants: {
    outline: { color: "green", outlineStyle: "dotted" },

    priceDiscount: {
      container: {
        color: "Base/neutralPrimary",
        paddingX: 2,
        paddingY: 1,
        borderRadius: "md",
        background: "linear-gradient(88.62deg, #01AFD6 0%, #16C6EE 52.87%, #56E1FF 100%)",
        ...getTextStyles("pora").MediumSubtitleSecondary,
      },
    },

    error: {
      container: {
        color: "Other/Error",
        padding: 1.5,
        borderRadius: "xl",
        background: "Base/neutralPrimary",
        ...getTextStyles("pora").MediumSubtitleTertiary,
      },
    },
  },
}
const loviTag = {
  variants: {
    ...poraTag.variants,
    priceDiscount: {
      container: {
        // Subtitle/Secondary
        color: "Base/neutralPrimary",
        paddingX: 2,
        paddingY: 1,
        borderRadius: "16px",
        backgroundColor: "Base/accentPrimary",
      },
    },
  },
}

const TagTheme = (theme: Theme): ComponentStyleConfig => (theme === "lovi" ? loviTag : poraTag)

const headingSizes = (theme: Theme) =>
  Object.fromEntries(
    Object.entries(getTextStyles(theme)).filter(
      ([name]) => name.startsWith("Medium") || name.startsWith("Header") || name.startsWith("Lead")
    )
  )

const HeadingTheme = (theme: Theme): ComponentStyleConfig => ({
  baseStyle: {
    fontFamily: "heading",
    fontWeight: 500,
  },
  sizes: headingSizes(theme),
  variants: {
    text: {
      fontFamily: "body",
    },
  },
})

const Divider: ComponentStyleConfig = {
  baseStyle: {
    opacity: 1,
  },
}

export const getComponents = (theme: Theme) => ({
  Button: ButtonTheme(theme),
  RadioAppButton: RadioAppButtonTheme(theme),
  PaymentSwitchButton: PaymentSwitchButtonTheme(theme),
  Heading: HeadingTheme(theme),
  Link,
  Tag: TagTheme(theme),
  Divider,
})
