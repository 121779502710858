import { noop } from "~/utils"
import { getTestQueryParams } from "~/utils/getTestQueryParams"
import { CONFIG } from "../config"
import { PaywallV2 } from "../generated/paywall"
import { SavePaymentDataRequest } from "../generated/paid_users_processor/requests"
import type { CountryCode } from "../types"
import { appendParams } from "../utils/url"

export class ApiError extends Error {}

const defaultParams = {
  client: "web",
  format: "json",
}

export const paths = {
  getPaywallData: appendParams(CONFIG.endpoints.getPaywallData, defaultParams),
  getCountryCode: appendParams(CONFIG.endpoints.getCountryCode, defaultParams),
  createStripeIntent: appendParams(CONFIG.endpoints.createStripeIntent, defaultParams),
  skipTrialStripeIntent: appendParams(CONFIG.endpoints.createSkipTrialStripeIntent, defaultParams),
  buyWorkbookPalta: appendParams(CONFIG.endpoints.buyWorkbookPalta, defaultParams),
  makePurchasePalta: appendParams(CONFIG.endpoints.makePurchasePalta, defaultParams),
  savePaymentStatus: appendParams(CONFIG.endpoints.savePaymentData, defaultParams),
  log: CONFIG.endpoints.log,
}

const JSON_POST_OPTIONS = {
  method: "POST",
  mode: "cors",
  cache: "no-cache",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
} as const

type SendLogType = { event: string; params?: Record<string, unknown> }
export let sendLog: (data: SendLogType) => void = noop

if (CONFIG.endpoints.log) {
  let user_id: string | null = ""
  if (!user_id) {
    user_id = new URLSearchParams(window.location.search).get("user_id")
  }
  sendLog = ({ event, params = {} }: SendLogType) => {
    params["page_url"] = window.location.href
    params["user_id"] = user_id || ""
    navigator.sendBeacon(CONFIG.endpoints.log, JSON.stringify({ event, params }))
  }
}

const getPaywallDataFetchParams = ({
  user_id,
  interview_variant,
  country,
}: {
  user_id: UserId
  interview_variant: string
  country: CountryCode
}) => {
  return appendParams(paths.getPaywallData, {
    ...getTestQueryParams(),
    user_id,
    interview_variant,
    country,
  })
}

export const getPaywallData = async (
  user_id: UserId,
  interview_variant: string,
  country: CountryCode
): Promise<PaywallV2> => {
  const resp = await fetch(getPaywallDataFetchParams({ user_id, interview_variant, country }))

  if (!resp.ok) {
    throw new Error(`Can't fetch data from ${resp.url}`)
  }
  const data = await resp.json()
  return PaywallV2.fromJSON(data)
}

export const getCountryCode = async (): Promise<CountryCode> => {
  const test_country = getTestQueryParams()["test_country"]
  if (test_country) {
    return test_country.toUpperCase() as CountryCode
  }

  const resp = await fetch(paths.getCountryCode)
  if (!resp.ok) {
    throw new Error(`Can't fetch data from ${resp.url}`)
  }
  const data = await resp.json()
  return data?.country_code as CountryCode
}

export const savePaymentData = async ({
  email,
  provider,
  paymentId,
  userId,
}: {
  email: string
  provider: string
  paymentId: string | undefined
  userId: string
}): Promise<undefined> => {
  const body = SavePaymentDataRequest.toJSON(
    SavePaymentDataRequest.create({ email, provider, payment_id: paymentId, user_id: userId })
  )

  const resp = await fetch(paths.savePaymentStatus, {
    ...JSON_POST_OPTIONS,
    body: JSON.stringify(body),
  })
  if (!resp.ok) {
    throw new Error(`Can't fetch data from ${resp.url}`)
  }
  return
}

type StripeClientSecret = string

export class PaymentApiError extends Error {}
export const createStripeIntent = async ({
  paymentMethodId,
  priceId,
  email,
  userId,
  initialPriceCents,
}: {
  paymentMethodId: PaymentMethodId
  priceId: PriceId
  email: string
  userId: string
  initialPriceCents: number
}): Promise<StripeClientSecret> => {
  const body = {
    payment_method_id: paymentMethodId,
    stripe_price_id: priceId,
    email,
    user_id: userId,
    initial_price_cents: initialPriceCents,
  }
  const resp = await fetch(paths.createStripeIntent, {
    ...JSON_POST_OPTIONS,
    body: JSON.stringify(body),
  })
  const data = await resp.json()
  if (!resp.ok) {
    if (data.message) {
      throw new PaymentApiError(data.message, { cause: data })
    } else {
      throw new ApiError(`Can't fetch data from ${resp.url}`)
    }
  }

  return data.clientSecret
}

export const createStripeSkipTrialIntent = async ({
  priceId: user_id,
  userId: price_id,
}: {
  /* TODO fix types */
  priceId: string
  userId: string
}): Promise<StripeClientSecret> => {
  const body = {}
  const url = appendParams(paths.skipTrialStripeIntent, { user_id, price_id })
  const resp = await fetch(url, {
    ...JSON_POST_OPTIONS,
    body: JSON.stringify(body),
  })
  const data = await resp.json()
  if (!resp.ok) {
    if (data.message) {
      throw new PaymentApiError(data.message, { cause: data })
    } else {
      throw new ApiError(`Can't fetch data from ${resp.url}`)
    }
  }

  return data.clientSecret
}

export const makePaltaPurchase = async ({
  priceId: price_id,
  userId: user_id,
}: {
  /* TODO fix types */
  priceId: string
  userId: string
}): Promise<boolean> => {
  const url = appendParams(paths.makePurchasePalta, { user_id, price_id })
  const resp = await fetch(url, {
    ...JSON_POST_OPTIONS,
    body: JSON.stringify({}),
  })
  const data = await resp.json()
  if (!resp.ok) {
    if (data.message) {
      throw new PaymentApiError(data.message, { cause: data })
    } else {
      throw new ApiError(`Can't fetch data from ${resp.url}`)
    }
  }

  return true
}
