import { useMemo } from "react"

if (!("gtag" in window)) {
  ;(window as Window)["gtag"] = () => undefined
}
const track = (action: string, values: Record<string, unknown>) => {
  window.gtag("event", action, values)
}

const purchase = (values: Record<string, unknown> = {}) => track("purchase", values)

const pageView = (path: string, values: Record<string, unknown>) =>
  track("page_view", {
    page_path: path,
    page_title: window.document.title,
    ...values,
  })

export const useGoogleAnalytics = () =>
  useMemo(
    () => ({
      track,
      purchase,
      pageView,
    }),
    []
  )
